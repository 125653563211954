import { HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { CustomHttpClient } from 'src/utils/customHttpClient';

const useSignalRProductImportStatusConnection = () => {
  const [
    signalRPProductImportConnection,
    setSignalRConnectionProductImport
  ] = useState(null);

  const getToken: string = localStorage.getItem('accessToken');
  
  useEffect(() => {
    const { REACT_APP_SOCKET_URL } = process.env;

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${REACT_APP_SOCKET_URL}adminConsoleHub`, {
        accessTokenFactory: () => localStorage.getItem('accessToken'), httpClient: new CustomHttpClient()
      })
      .withAutomaticReconnect()
      .build();

    setSignalRConnectionProductImport(newConnection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    signalRPProductImportConnection
  };
};

export default useSignalRProductImportStatusConnection;