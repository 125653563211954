import moment from 'moment';
import mock from 'src/utils/mock';
import {
    UserRolesInterface,
} from 'src/types/userRoles';

const userRoles: UserRolesInterface[] = [
    {
        id: 1,
        name: 'Super Admin',
        notes: 'Stark team member. Has access to all clients.',
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 2,
        name: 'Client Admin',
        notes: 'Access to all stores within an organization (client). Can adjust certain administrative settings.',
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 3,
        name: 'Store Manager',
        notes: 'Access to a single store. Can access all store reports, and can adjust certain store settings. Full clerk console capabilities.',
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 4,
        name: 'Floor Manager',
        notes: 'Monitor all stations for a store. Can utilize clerk console to assist with transactions.',
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 5,
        name: 'Clerk',
        notes: 'Monitor select stations.',
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
];

mock.onGet('/api/user-roles').reply(() => {

    return [200, { userRoles }];
});

mock.onGet('/api/user-roles/1').reply((config) => {

    const userRole: UserRolesInterface = userRoles.filter(userRole => userRole.id === parseInt(config.params.id))[0];

    return [200, { userRole }];
});