import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import NProgress from 'nprogress';
import type { AppThunk } from 'src/store';
import { ProductImportDetails } from 'src/types/productImports';
import { api } from 'src/utils/api';
import genericActions, { emptyListingObject } from './common';
export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: ProductImportDetails[],
  pageSize: number,
  skip: number,
}
export interface ReduxStoreObject {
  selectedRecord?: ReduxStoreListObject;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const initialState: ReduxStoreObject = {
  selectedRecord: emptyListingObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'ProductImportDetails',
  initialState,
  reducers: {
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, error, message, loading } = action.payload;
      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxStoreObject) {
      state.selectedRecord = emptyListingObject;
    },
    reset(state: ReduxStoreObject) {
      const { selectedRecord, success, error, loading, actionType, message } = initialState;
      state.selectedRecord = selectedRecord;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = actionType;
      state.loading = loading;
    },
  }
});

export const reducer = slice.reducer;

const resource = 'ProductImports';
export const { getAll, set, put, patch, remove, resetError, resetSuccess, reset } = genericActions(slice.actions, resource, emptyListingObject);

export const get = (id, params = {}): AppThunk => async (dispatch) => {
  try {
    NProgress.start();
    dispatch(slice.actions.setLoading({ loading: true }));
    const { status, data } = await api(`${resource}/${id}/details`, 'get', params);

    const getData = (data, success, error, message): ReduxStoreObject => ({
      selectedRecord: data,
      success,
      error,
      message,
      loading: false,
    });
    if (status === 200)
      dispatch(slice.actions.get(getData(data, false, '', true)));
    else {
      dispatch(slice.actions.get(getData([], false, true, 'An error while loading products import detail!')));
      dispatch(slice.actions.resetError());
    }

    NProgress.done();
  } catch (err) {
    console.log(err);
  }
};

export default slice;