import React, { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import ClientGuard from 'src/components/ClientGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import useCheckRole from 'src/hooks/useCheckRole';
import { UserRoles } from 'src/types/user';
import useAuth from 'src/hooks/useAuth';
import useAcl, { Actions } from './hooks/useAcl';
import { Resources } from './types/resources';

const NotFoundView = lazy(() => import('src/views/errors/NotFoundView'));
const LoginViewComponent = lazy(() => import('src/views/auth/LoginView'));
const ForgotPasswordComponent = lazy(() => import('src/views/account/AccountView/ForgotPassword'));
const ResetPasswordComponent = lazy(() => import('src/views/account/AccountView/ResetPassword'));
const ClientSelectionViewComponent = lazy(() => import('src/views/clients/ClientSelectionView'));
const ClientListViewComponent = lazy(() => import('src/views/clients/ClientListView'));
const ClientCreateViewComponent = lazy(() => import('src/views/clients/ClientCreateView'));
const ClientDetailsViewComponent = lazy(() => import('src/views/clients/ClientDetailsView'));
const ClientEditViewComponent = lazy(() => import('src/views/clients/ClientEditView'));

const ClientIntegrationListViewComponent = lazy(() => import('src/views/clientIntegration/clientIntegrationListView'));
const ClientIntegrationCreateViewComponent = lazy(() => import('src/views/clientIntegration/clientIntegrationCreateView'));
const ClientIntegrationDetailsViewComponent = lazy(() => import('src/views/clientIntegration/clientIntegrationDetailsView'));
const ClientIntegrationEditViewComponent = lazy(() => import('src/views/clientIntegration/clientIntegrationEditView'));

const UserListViewComponent = lazy(() => import('src/views/user/UserListView'));
const UserCreateViewComponent = lazy(() => import('src/views/user/UserCreateView'));
const UserEditViewComponent = lazy(() => import('src/views/user/UserEditView'));
const UserDetailsViewComponent = lazy(() => import('src/views/user/UserDetailsView'));
const UserRolesListViewComponent = lazy(() => import('src/views/userRoles/userRolesListView'));
const UserRoleCreateViewComponent = lazy(() => import('src/views/userRoles/userRoleCreateView'));
const UserRoleEditViewComponent = lazy(() => import('src/views/userRoles/userRoleEditView'));
const StoreListViewComponent = lazy(() => import('src/views/store/StoreListView'));
const StoreCreateViewComponent = lazy(() => import('src/views/store/StoreCreateView'));
const StoreDetailsViewComponent = lazy(() => import('src/views/store/StoreDetailsView'));
const StoreEditViewComponent = lazy(() => import('src/views/store/StoreEditView'));
const ProductListViewComponent = lazy(() => import('src/views/product/ProductListView'));
const ProductCreateViewComponent = lazy(() => import('src/views/product/ProductCreateView'));
const ProductEditViewComponent = lazy(() => import('src/views/product/ProductEditView'));
const ProductDetailsViewComponent = lazy(() => import('src/views/product/ProductDetailsView'));
const StationListViewComponent = lazy(() => import('src/views/station/StationListView'));
const StationHistoryListViewComponent = lazy(() => import('src/views/station/StationHistoryListView'));
const StationLogsListViewComponent = lazy(() => import('src/views/station/StationLogsListView'));
const StationCreateViewComponent = lazy(() => import('src/views/station/StationCreateView'));
const StationEditViewComponent = lazy(() => import('src/views/station/StationEditView'));
const StationDetailsViewComponent = lazy(() => import('src/views/station/StationDetailsView'));
const StationGroupListViewComponent = lazy(() => import('src/views/stationGroup/StationGroupListView'));
const StationGroupEditViewComponent = lazy(() => import('src/views/stationGroup/StationGroupEditView'));
const StationGroupCreateViewComponent = lazy(() => import('src/views/stationGroup/StationGroupCreateView'));
const StationGroupDetailsViewComponent = lazy(() => import('src/views/stationGroup/StationGroupDetailsView'));
const GlobalProfileListViewComponent = lazy(() => import('src/views/globalProfiles/GlobalProfileListView'));
const GlobalProfileEditViewComponent = lazy(() => import('src/views/globalProfiles/GlobalProfileEditView'));
const GlobalProfileCreateViewComponent = lazy(() => import('src/views/globalProfiles/GlobalProfileCreateView'));
const DashboardViewComponent = lazy(() => import('src/views/reports/DashboardView'));
const StationDashboardViewComponent = lazy(() => import('src/views/reports/StationDashboardView'));
const StationsHealthComponent = lazy(() => import('src/views/reports/StationDashboardView/StationsHealth/StationsHealth'));
const LocationCreateViewComponent = lazy(() => import('src/views/locations/LocationCreateView'));
const LocationDetailsViewComponent = lazy(() => import('src/views/locations/LocationDetailsView'));
const LocationEditViewComponent = lazy(() => import('src/views/locations/LocationEditView'));
const LocationListViewComponent = lazy(() => import('src/views/locations/LocationListView'));
const TransactionListViewComponent = lazy(() => import('src/views/transaction/TransactionListView'));
const TunnelHistoryListViewComponent = lazy(() => import('src/views/tunnelhistory/TunnelHistoryListView'));
const UnknownItemsComponent = lazy(() => import('src/views/reports/UnknownItems'));
const AlertsAndNotificationsListViewComponent = lazy(() => import('src/views/alertsAndNotifications/AlertsAndNotificationsListView'));
const AlertsAndNotificationsEditViewComponent = lazy(() => import('src/views/alertsAndNotifications/AlertsAndNotificationsEditView'));
const OperatingScheduleComponent = lazy(() => import('src/views/operatingSchedule'));
const ProductImportHistoryListViewComponent = lazy(() => import('src/views/productImportHistory/ProductImportHistoryListView'));
const ProductImportDetailsViewComponent = lazy(() => import('src/views/productImportHistory/ProductImportDetailsView'));
const StationsComponent = lazy(() => import('src/views/reports/ClerkDashboard/Stations'));
const StationDetailComponent = lazy(() => import('src/views/reports/ClerkDashboard/StationDetail'));
const TunnelListViewComponent = lazy(() => import('src/views/tunnel/TunnelListView'));
const TunnelEditViewComponent = lazy(() => import('src/views/tunnel/TunnelEditView'));
const TunnelCreateViewComponent = lazy(() => import('src/views/tunnel/TunnelCreateView'));
const TunnelDetailsViewComponent = lazy(() => import('src/views/tunnel/TunnelDetailsView'));
const DiscountListViewComponent = lazy(() => import('src/views/discounts/DiscountListView'));
const DiscountCreateViewComponent = lazy(() => import('src/views/discounts/DiscountCreateView'));
const DiscountEditViewComponent = lazy(() => import('src/views/discounts/DiscountEditView'));
const DiscountDetailPageComponent = lazy(() => import('src/views/discounts/DiscountDetailView'));

let canReadClients: any,
  canReadGlobalProfiles: any,
  canReadUsers: any,
  canReadProducts: any,
  canReadStores: any,
  canReadStationGroups: any,
  canReadStations: any,
  canReadLocations: any,
  canReadClientIntegrations:any,
  canReadUnknownItems: any,
  canReadDashboard: any,
  canReadTransactionHistory: any,
  canReadTunnelHistory: any,
  canReadAlertsAndNotifications: any,
  canReadOperatingSchedule: any,
  canReadTunnels: any,
  isTunnelManager: any,
  canReadDiscounts: any;

export const renderRoutes = (): JSX.Element => (

  <Routes>
    <Route path='/404' element={<MainLayout>  <Suspense fallback={<LoadingScreen />}><NotFoundView /></Suspense></MainLayout>} />
    <Route path='/' element={<GuestGuard><Suspense fallback={<LoadingScreen />}><LoginViewComponent /></Suspense></GuestGuard>} />
    <Route path='/login-unprotected' element={<Suspense fallback={<LoadingScreen />}><LoginViewComponent /></Suspense>} />
    <Route path='/forgot-password' element={<Suspense fallback={<LoadingScreen />}><ForgotPasswordComponent /></Suspense>} />
    <Route path='/reset-password' element={<Suspense fallback={<LoadingScreen />}><ResetPasswordComponent /></Suspense>} />
    <Route path='/choose-client' element={<AuthGuard><Suspense fallback={<LoadingScreen />}><ClientSelectionViewComponent /></Suspense> </AuthGuard>} />
    <Route path='/app' element={<AuthGuard><ClientGuard><DashboardLayout /></ClientGuard></AuthGuard>}>
      <Route path='management/clients' element={canReadClients? <Suspense fallback={<LoadingScreen />}><ClientListViewComponent /></Suspense>: <Navigate to="/404" />} />
      <Route path='management/clients/create' element={canReadClients?<Suspense fallback={<LoadingScreen />}><ClientCreateViewComponent /></Suspense>: <Navigate to="/404" />} />
      <Route path='management/clients/:id' element={canReadClients?<Suspense fallback={<LoadingScreen />}><ClientDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/clients/:id/edit' element={canReadClients?<Suspense fallback={<LoadingScreen />}><ClientEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/client-integration' element={canReadClientIntegrations ? <Suspense fallback={<LoadingScreen />}><ClientIntegrationListViewComponent/> </Suspense>: <Navigate to="/404" />} />
      <Route path='management/client-integration/:id/edit' element={canReadClientIntegrations ?  <Suspense fallback={<LoadingScreen />}><ClientIntegrationEditViewComponent/></Suspense>: <Navigate to="/404" />} />
      <Route path='management/client-integration/create' element={canReadClientIntegrations ?  <Suspense fallback={<LoadingScreen />}><ClientIntegrationCreateViewComponent/></Suspense>: <Navigate to="/404" />} />
      <Route path='management/client-integration/:id/view' element={canReadClientIntegrations ? <Suspense fallback={<LoadingScreen />}><ClientIntegrationDetailsViewComponent/></Suspense>: <Navigate to="/404" />} />    
      <Route path='management/discounts' element={canReadDiscounts ? <Suspense fallback={<LoadingScreen />}><DiscountListViewComponent /></Suspense> : <Navigate to="/404" />} />      
      <Route path='management/discounts/create' element={canReadDiscounts ? <Suspense fallback={<LoadingScreen />}><DiscountCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/discounts/:id/edit' element={canReadDiscounts?<Suspense fallback={<LoadingScreen />}><DiscountEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/discounts/:id' element={canReadDiscounts?<Suspense fallback={<LoadingScreen />}><DiscountDetailPageComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/users' element={canReadUsers?<Suspense fallback={<LoadingScreen />}><UserListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/users/type/:type' element={canReadUsers?<Suspense fallback={<LoadingScreen />}><UserListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/users/create' element={canReadUsers?<Suspense fallback={<LoadingScreen />}><UserCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/users/create/type/:type' element={canReadUsers?<Suspense fallback={<LoadingScreen />}><UserCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/users/:id/edit' element={canReadUsers?<Suspense fallback={<LoadingScreen />}><UserEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/users/:id/edit/type/:type' element={canReadUsers?<Suspense fallback={<LoadingScreen />}><UserEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/users/:id/view/type/:type' element={canReadUsers?<Suspense fallback={<LoadingScreen />}><UserDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/user-roles' element={ canReadUsers?<Suspense fallback={<LoadingScreen />}><UserRolesListViewComponent /></Suspense>: <Navigate to="/404" />} /> 
      <Route path='management/add-user-role' element={ canReadUsers?<Suspense fallback={<LoadingScreen />}><UserRoleCreateViewComponent /></Suspense>: <Navigate to="/404" />} />
      <Route path='management/edit-user-role/:id' element={ canReadUsers?<Suspense fallback={<LoadingScreen />}><UserRoleEditViewComponent /></Suspense>: <Navigate to="/404" />} />
      <Route path='management/stores' element={ canReadStores?  <Suspense  fallback={<LoadingScreen />}><StoreListViewComponent /> </Suspense> : <Navigate to="/404" />} />
      <Route path='management/stores/create' element={ canReadStores?  <Suspense  fallback={<LoadingScreen />}><StoreCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stores/:id' element={ canReadStores?  <Suspense  fallback={<LoadingScreen />}><StoreDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stores/:customerId' element={ canReadStores?  <Suspense  fallback={<LoadingScreen />}><StoreDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stores/:id/edit' element={ canReadStores?  <Suspense  fallback={<LoadingScreen />}><StoreEditViewComponent /></Suspense> : <Navigate to="/404" />} />          
      <Route path='management/products' element={ canReadProducts? <Suspense fallback={<LoadingScreen />}><ProductListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/products/create' element={ canReadProducts? <Suspense fallback={<LoadingScreen />}><ProductCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/products/:id/edit' element={ canReadProducts? <Suspense fallback={<LoadingScreen />}><ProductEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/products/:id' element={ canReadProducts? <Suspense fallback={<LoadingScreen />}><ProductDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />     
      <Route path='management/stations' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stations/:id/history' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationHistoryListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stations/:id/logs' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationLogsListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stations/create' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stations/:id/edit' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stations/:id' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/station-groups' element={ canReadStationGroups? <Suspense fallback={<LoadingScreen />}><StationGroupListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/station-groups/:id/edit' element={ canReadStationGroups? <Suspense fallback={<LoadingScreen />}><StationGroupEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/station-groups/create' element={ canReadStationGroups? <Suspense fallback={<LoadingScreen />}><StationGroupCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/station-groups/:id/view' element={ canReadStationGroups? <Suspense fallback={<LoadingScreen />}><StationGroupDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />    
      <Route path='management/locations' element={ canReadLocations? <Suspense fallback={<LoadingScreen />}><LocationListViewComponent/> </Suspense> : <Navigate to="/404" />} />
      <Route path='management/locations/:id/edit' element={ canReadLocations? <Suspense fallback={<LoadingScreen />}><LocationEditViewComponent/></Suspense> : <Navigate to="/404" />} />
      <Route path='management/locations/create' element={ canReadLocations? <Suspense fallback={<LoadingScreen />}><LocationCreateViewComponent/></Suspense> : <Navigate to="/404" />} />
      <Route path='management/locations/:id/view' element={ canReadLocations? <Suspense fallback={<LoadingScreen />}><LocationDetailsViewComponent/></Suspense> : <Navigate to="/404" />} />    
      <Route path='management/tunnels' element={ canReadTunnels? <Suspense fallback={<LoadingScreen />}><TunnelListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/tunnels/:id/edit' element={ canReadTunnels? <Suspense fallback={<LoadingScreen />}><TunnelEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/tunnels/create' element={ canReadTunnels? <Suspense fallback={<LoadingScreen />}><TunnelCreateViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/tunnels/:id/view' element={ canReadTunnels? <Suspense fallback={<LoadingScreen />}><TunnelDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />    
      <Route path='management/global-profiles' element={ canReadGlobalProfiles? <Suspense fallback={<LoadingScreen />}><GlobalProfileListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/global-profiles/:id/edit' element={ canReadGlobalProfiles? <Suspense fallback={<LoadingScreen />}><GlobalProfileEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/global-profiles/create' element={ canReadGlobalProfiles? <Suspense fallback={<LoadingScreen />}><GlobalProfileCreateViewComponent /></Suspense> : <Navigate to="/404" />} />  
      <Route path='management' element={<Navigate to="management/users" />} />
      <Route path='reports/dashboard' element={ canReadDashboard? <Suspense fallback={<LoadingScreen />}><DashboardViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='reports/station-dashboard' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationDashboardViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/stations-health' element={ canReadStations? <Suspense fallback={<LoadingScreen />}><StationsHealthComponent /></Suspense> : <Navigate to="/404" />} />    
      <Route path='reports' element={<Navigate to={GetDefaultUrl()} />} />
      <Route path='reports/transaction' element={ canReadTransactionHistory? <Suspense fallback={<LoadingScreen />}><TransactionListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='reports/tunnel' element={ canReadTunnelHistory? <Suspense fallback={<LoadingScreen />}><TunnelHistoryListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='reports/unknown-items' element={ canReadUnknownItems? <Suspense fallback={<LoadingScreen />}><UnknownItemsComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='reports/alerts-and-notifications' element={ canReadAlertsAndNotifications? <Suspense fallback={<LoadingScreen />}><AlertsAndNotificationsListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='reports/alerts-and-notifications/:id/edit' element={ canReadAlertsAndNotifications? <Suspense fallback={<LoadingScreen />}><AlertsAndNotificationsEditViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='reports/operating-schedule' element={ canReadOperatingSchedule? <Suspense fallback={<LoadingScreen />}><OperatingScheduleComponent /></Suspense> : <Navigate to="/404" />} />      
      <Route path='management/import-product-history' element={ canReadProducts? <Suspense fallback={<LoadingScreen />}><ProductImportHistoryListViewComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='management/imports-products/:id' element={ canReadProducts? <Suspense fallback={<LoadingScreen />}><ProductImportDetailsViewComponent /></Suspense> : <Navigate to="/404" />} />      
      <Route path='reports/clerk-dashboard' element={ !isTunnelManager? <Suspense fallback={<LoadingScreen />}><StationsComponent /></Suspense> : <Navigate to="/404" />} />
      <Route path='reports/clerk-dashboard/station/:id' element={ !isTunnelManager? <Suspense fallback={<LoadingScreen />}><StationDetailComponent /></Suspense> : <Navigate to="/404" />} /> 
      
      <Route index element={<Navigate to = {GetDefaultUrl()} />} />
  
      <Route path='*' element={<Navigate to="/404" />} />
    </Route>
    <Route path='*' element={<Navigate to="/404" />} />
  </Routes>
);

const GetDefaultUrl=()=>{
  const { user } = useAuth();
  const isTunnelManager = useCheckRole(UserRoles.tunnelManager, user);
  return isTunnelManager?'reports/tunnel':'reports/dashboard'
}

const AdminRoutes: FC = () => {
  canReadClients = useAcl({ resource: Resources.clients, action: Actions.read });
  canReadGlobalProfiles = useAcl({ resource: Resources.globalProfiles, action: Actions.read });
  canReadUsers = useAcl({ resource: Resources.users, action: Actions.read });
  canReadProducts = useAcl({ resource: Resources.products, action: Actions.read });
  canReadStores = useAcl({ resource: Resources.stores, action: Actions.read });
  canReadStationGroups = useAcl({ resource: Resources.stationGroups, action: Actions.read });
  canReadStations = useAcl({ resource: Resources.stations, action: Actions.read });
  canReadLocations = useAcl({ resource: Resources.locations, action: Actions.read });
  canReadClientIntegrations = useAcl({ resource: Resources.clientIntegrations, action: Actions.read });
  canReadUnknownItems = useAcl({ resource: Resources.products, action: Actions.read });
  canReadDashboard = useAcl({ resource: Resources.dashboard, action: Actions.read });
  canReadTransactionHistory = useAcl({ resource: Resources.transactionHistory, action: Actions.read });
  canReadTunnelHistory = useAcl({ resource: Resources.tunnelHistory, action: Actions.read});
  canReadAlertsAndNotifications = useAcl({ resource: Resources.alertsAndNotifications, action: Actions.read });
  canReadOperatingSchedule = useAcl({ resource: Resources.operatingSchedules, action: Actions.read });
  canReadTunnels = useAcl({ resource: Resources.tunnels, action: Actions.read });
  canReadDiscounts = useAcl({ resource: Resources.discounts, action: Actions.read });
  const { user } = useAuth();
  isTunnelManager = useCheckRole(UserRoles.tunnelManager, user);
  return (
    renderRoutes()
  );
};
export default AdminRoutes;
