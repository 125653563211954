import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { api } from 'src/utils/api';
import { useSnackbar } from 'notistack';
import { useSelector } from 'src/store';
import { appInsights,severityLevel} from 'src/utils/appInsight';

const useGetClients = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [clients, setClients] = useState<{ id: number; name: string; }[]>([]);
  const [fetchClients, setFetchClients] = useState<boolean>(false);
  const { actionType: clientActionType, success: clientAddUpdateSuccess } = useSelector(state => state.client);
  const decoded: any = jwt_decode(localStorage.getItem('accessToken'));
  if (decoded?.allClients === undefined || decoded?.allClients === 'true') {
    if (fetchClients === false)
      setFetchClients(true);
  }

  const getClients = async () => {
    const { data: { rows }, status } = await api('clients', 'get', { page: 0, pageSize: 0, orderBy: 'organizationName ASC' });
    appInsights.trackTrace({ message:'get Clients', properties:{'statusCode': status, 'response': rows}, severityLevel: severityLevel.Information });
    if (status === 200) {
      setClients(rows?.map(rec => ({ id: rec.id, name: rec.organizationName })) || []);
    } else {
      enqueueSnackbar('Network request failed.', {
        variant: 'error',
        autoHideDuration: 3000
      });
      appInsights.trackTrace({ message:'error in getting Clients', properties:{'errorCode': status}, severityLevel: severityLevel.Warning });
    }
  };

  useEffect(() => {
    if (decoded?.allClients && decoded?.allClients !== 'true') {
      setClients((decoded?.clients) || []); // Simply set clients from JWT.
    } else {
      getClients(); // get client from API.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((clientActionType === 'set' || clientActionType === 'remove') && clientAddUpdateSuccess) {
      getClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientActionType, clientAddUpdateSuccess]);
  return clients;
};

export default useGetClients;
