import type { StationGroup } from 'src/types/stationGroups';
import { layout } from 'src/types/stationGroups';
import mock from 'src/utils/mock';

mock.onGet('/api/station-groups').reply(() => {
  const data: StationGroup[] = [
    {
      'id': 2,
      'name': 'Station Group 1',
      'locationDescription': '',
      'layout': layout.horizontal,
      'isActive': true,
      'store': {
        'id': 1,
        'name': 'test1',
        'storeUniqueId': 'ABCDEF',
        'email': 'andrew@gmail.com',
        'phone': '123456789',
        'contactName': 'Andrew Rutter',
        'notes': '<p>Thanks! Good Job.</p>',
        'clientId': 1,
        'address': {
          'address1': 'Address 1',
          'address2': 'Address 2',
          'city': 'abc',
          'state': 'GA',
          'zip': '30004'
        }
      }
    },
    {
      'id': 3,
      'name': 'Station Group 2',
      'locationDescription': '',
      'layout': layout.horizontal,
      'isActive': true,
      'store': {
        'id': 1,
        'name': 'test1',
        'storeUniqueId': 'ABCDEF',
        'email': 'andrew@gmail.com',
        'phone': '123456789',
        'contactName': 'Andrew Rutter',
        'notes': '<p>Thanks! Good Job.</p>',
        'clientId': 1,
        'address': {
          'address1': 'Address 1',
          'address2': 'Address 2',
          'city': 'abc',
          'state': 'GA',
          'zip': '30004'
        }
      }
    },
  ];

  return [200, data];
});

mock.onGet('/api/station-groups/2').reply(() => {
  const data: StationGroup = {
    'id': 2,
    'name': 'Station Group 1',
    'locationDescription': '',
    'layout': layout.horizontal,
    'isActive': true,
    'store': {
      'id': 1,
      'name': 'test1',
      'storeUniqueId': 'ABCDEF',
      'email': 'andrew@gmail.com',
      'phone': '123456789',
      'contactName': 'Andrew Rutter',
      'notes': '<p>Thanks! Good Job.</p>',
      'clientId': 1,
      'address': {
        'address1': 'Address 1',
        'address2': 'Address 2',
        'city': 'abc',
        'state': 'GA',
        'zip': '30004'
      }
    }
  };

  return [200, data];
});