import { DefaultHttpClient } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import TokenService from './tokenService';
import { refreshAccessToken } from './api';

export class CustomHttpClient extends DefaultHttpClient {
	constructor() {
    	super(console); // the base class wants a signalR.ILogger
  	}

	public async send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
		request.headers = request.headers || {};
		request.headers['Authorization'] = `Bearer ${TokenService.getLocalAccessToken()}`;
		
		try {
			const response = await super.send(request);
			return response;
		} catch (e) {
			if (e instanceof signalR.HttpError && e.statusCode === 401) {
				const newToken = await refreshAccessToken();
				if (!newToken){
					throw new Error('Token Refresh Failed');
				}
				request.headers['Authorization'] = `Bearer ${newToken}`;
			} else {
				throw e;
			}
		}

		return await super.send(request);
	}
}