import NProgress from 'nprogress';
import type { AppThunk } from 'src/store';
import { api } from 'src/utils/api';
import axios from 'src/utils/axios';
import { removeHTML, truncate } from 'src/utils/common';

NProgress.configure({ trickle: false });

export const emptyListingObject = {
  page: -1,
  totalPages: 1,
  totalCount: 1,
  rows: [],
  pageSize: 1,
  skip: 0,
};

const genericActions = (actions: any, resource: string, emptyObject: any) => {
  const getAll = (params = {}): AppThunk => async (dispatch) => {
    NProgress.start();
    dispatch(actions.setLoading({ loading: true }));
    const { data, status } = await api(resource, 'get', params);
    const getData = (data: any, error: boolean, success: boolean, message: string) => ({
      records: data, error, success, message, loading: false,
    });
    if (status === 200)
      dispatch(actions.getAll(getData(data, false, false, '')));
    else {
      let msg = `An error while loading ${resource}!`;
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(actions.getAll(getData(emptyListingObject, true, false, msg)));
    }
    NProgress.done();
  };

  const getAllForDropdown = (params = {}): AppThunk => async (dispatch) => {
    NProgress.start();
    dispatch(actions.setLoading({ loading: true }));
    const { data, status } = await api(resource, 'get', params);
    const getData = (data: any, error: boolean, success: boolean, message: string) => ({
      records: data, error, success, message, loading: false,
    });
    if (status === 200)
      dispatch(actions.getAllForDropdown(getData(data, false, false, '')));
    else {
      let msg = `An error while loading ${resource}!`;
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(actions.getAllForDropdown(getData(emptyListingObject, true, false, msg)));
    }
    NProgress.done();
  };

  const get = (id: number): AppThunk => async (dispatch) => {
    NProgress.start();
    const getData = (data: any, error: boolean, success: boolean, message: string) => ({
      selectedRecord: data, error, success, message, loading: false,
    });
    dispatch(actions.setLoading({ loading: true }));
    dispatch(actions.resetSelected());
    const { data, status } = await api(`${resource}/${id}`, 'get');
    if (status === 200)
      dispatch(actions.get(getData(data, false, false, '')));
    else {
      let msg = 'An error while loading details!';
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(actions.get(getData(emptyObject, true, false, msg)));
    }
    NProgress.done();
  };

  const set = (obj: any, objName: string): AppThunk => async (dispatch) => {
    NProgress.start();
    dispatch(actions.setLoading({ loading: true }));
    const { status, data } = await api(`${resource}`, 'post', obj);
    const getData = (error: boolean, success: boolean, message: string) => ({
      error, success, message, loading: false,
    });

    if (status === 200) {
      dispatch(actions.set(getData(false, true, `${truncate(objName)} has been created.`)));
    } else {
      let msg = `An error while creating ${truncate(objName)}!`;
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(actions.set(getData(true, false, msg)));
    }
    NProgress.done();
  };

  const put = (id: number, obj: any, objName: string): AppThunk => async (dispatch) => {
    NProgress.start();
    dispatch(actions.setLoading({ loading: true }));
    const { status, data } = await api(`${resource}/${id}`, 'put', obj);
    const getData = (error: boolean, success: boolean, message: string) => ({
      error, success, message, loading: false,
    });

    if (status === 200) {
      dispatch(actions.set(getData(false, true, `${truncate(objName)} has been updated.`)));
    } else {
      let msg = `An error while updating a ${(objName)}!`;
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(actions.set(getData(true, false, msg)));
    }
    NProgress.done();
  };

  const patch = (id: number, objName: string, patchName: string, patchValue: any): AppThunk => async (dispatch) => {
    NProgress.start();
    const { status, data } = await api(`${resource}/${id}/${patchName}/${patchValue}`, 'patch');
    const getData = (error: boolean, success: boolean, message: string) => ({
      error, success, message, loading: null,
    });

    if (status === 200) {
      dispatch(actions.set(getData(false, true, `The ${patchName} of ${truncate(objName)} has been changed.`)));
    } else {
      let msg = `An error while changing ${patchName} of ${truncate(objName)}!`;
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(actions.set(getData(true, false, msg)));
    }
    NProgress.done();
  };

  const remove = (id: number, objName: string): AppThunk => async (dispatch) => {
    NProgress.start();
    dispatch(actions.setLoading({ loading: true }));
    const { status, data } = await api(`${resource}/${id}`, 'delete');
    const getData = (error: boolean, success: boolean, message: string) => ({
      error, success, message, loading: false,
    });

    if (status === 200) {
      dispatch(actions.remove(getData(false, true, `${truncate(objName)} has been removed.`)));
    } else {
      let msg = `An error while removing ${truncate(objName)}!`;
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(actions.remove(getData(true, false, msg)));
    }
    NProgress.done();
  };

  const resetError = (): AppThunk => async (dispatch) => {
    dispatch(actions.resetError({}));
  };

  const resetSuccess = (): AppThunk => async (dispatch) => {
    dispatch(actions.resetSuccess({}));
  };

  const reset = (): AppThunk => async (dispatch) => {
    dispatch(actions.reset());
  };

  return { getAll, getAllForDropdown, get, set, put, patch, remove, resetError, resetSuccess, reset };
};

export const genericMockActions = (actions: any, resource: string, emptyObject: any) => {
  const getAll = (params = {}): AppThunk => async (dispatch) => {
    dispatch(actions.setLoading({ loading: true }));
    const { data, status } = await axios.get<{ data: any; }>(`/api/${resource}`, { params });
    const getData = (data: any, error: boolean, success: boolean, message: string) => ({
      records: data, error, success, message, loading: false,
    });
    if (status === 200)
      dispatch(actions.getAll(getData(data, false, false, '')));
    else
      dispatch(actions.getAll(getData([], true, false, `An error while loading ${resource}!`)));
  };

  const get = (id: number): AppThunk => async (dispatch) => {
    dispatch(actions.setLoading({ loading: true }));
    const { data, status } = await axios.get(`/api/${resource}/${id}`);
    const getData = (data: any, error: boolean, success: boolean, message: string) => ({
      selectedRecord: data, error, success, message, loading: false,
    });
    if (status === 200)
      dispatch(actions.get(getData(data, false, false, '')));
    else
      dispatch(actions.get(getData([], true, false, `An error while loading ${resource}!`)));
  };
  return { getAll, get };
};

export default genericActions;