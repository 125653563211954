import mock from 'src/utils/mock';
import { OperatingSchedule as OperatingScheduleInterface } from 'src/types/operatingSchedule';

const operatingSchedule: OperatingScheduleInterface[] = [
    {
        id: 3,
        startTime: '22:00',
        endTime: '21:00'
    },
    {
        id: 4,
        startTime: '22:00',
        endTime: '21:00'
    }
];

mock.onGet('/api/operatingSchedules').reply(() => {
    return [200, { operatingSchedule }];
});

mock.onGet('/api/operatingSchedules/1').reply((config) => {
    const operatingScheduleData = operatingSchedule.filter(schedule => schedule.id === parseInt(config.params.id))[0];
    return [200, { data: operatingScheduleData }];
});