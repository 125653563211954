import {
  ProductImportDetails
} from 'src/types/productImports';
import mock from 'src/utils/mock';

mock.onGet('/api/ProductImports/1').reply(() => {
  const data: ProductImportDetails[] =
    [{
      id: 1,
      name: 'testcecec',
      action: 'failed',
      description: 'test',
      epcPrefix: '',
      icon: '',
      source: 'CSV1',
      price: 8,
      message: '',
      fileName: 'fileName1',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 1,
    }, {
      id: 2,
      name: 'test1',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV2',
      price: 8,
      message: '',
      fileName: 'fileName2',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 3,
      name: 'test2',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV3',
      price: 8,
      message: '',
      fileName: 'fileName3',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 4,
      name: 'test3',
      action: 'rejected',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV4',
      price: 8,
      message: '',
      fileName: 'fileName4',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 5,
      name: 'test4',
      action: 'updated',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV5',
      price: 8,
      message: '',
      fileName: 'fileName5',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, { 
      id: 6,
      name: 'test5',
      action: 'rejected',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV6',
      price: 8,
      message: '',
      fileName: 'fileName6',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 7,
      name: 'test6',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV7',
      price: 8,
      message: '',
      fileName: 'fileName7',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 8,
      name: 'test7',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV8',
      price: 8,
      message: '',
      fileName: 'fileName8',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 9,
      name: 'test8',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV9',
      price: 8,
      message: '',
      fileName: 'fileName9',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 10,
      name: 'test9',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV10',
      price: 8,
      message: '',
      fileName: 'fileName10',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 11,
      name: 'test10',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV11',
      price: 8,
      message: '',
      fileName: 'fileName11',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 12,
      name: 'test11',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV12',
      price: 8,
      message: '',
      fileName: 'fileName12',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 13,
      name: 'test12',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV13',
      price: 8,
      message: '',
      fileName: 'fileName13',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 14,
      name: 'test13',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV14',
      price: 8,
      message: '',
      fileName: 'fileName14',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }, {
      id: 15,
      name: 'test14',
      action: 'added',
      description: 'testing',
      epcPrefix: '',
      icon: '',
      source: 'CSV15',
      price: 8,
      message: '',
      fileName: 'fileName15',
      imageUrl: '',
      notes: '',
      color: '',
      size: '',
      productImportId: 2,
    }];

  return [200, {
    'page': 1,
    'totalPages': 2,
    'totalCount': 13,
    'rows': data,
    'pageSize': 10,
    'skip': 0,
  }];
});