import React from 'react';
import type { FC } from 'react';
import { useTheme } from '@material-ui/core';
import type { Theme } from 'src/theme';
import { THEMES } from 'src/constants';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const theme = useTheme<Theme>();
  const logo = (theme.name === THEMES.EXO_LIGHT) ? '/static/logo.svg' : '/static/logo-white.svg';
  return (
    <img
      alt="Logo"
      src={logo}
      {...props}
    />
  );
};

export default Logo;
