import { restoreSettings } from 'src/contexts/SettingsContext';

export const getSelectedClientId = () => {

  const settings: any = restoreSettings();
  return parseInt(settings?.client || 0);
};

export const getSelectedClientName = () => {
  const settings: any = restoreSettings();
  return settings?.clientName || '';
};