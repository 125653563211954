/* eslint-disable no-unused-vars */
import { Alert } from './alert';
import { StationGroup } from './stationGroups';
import { MessageLevel } from './generic';
import { TransactionStatus } from './transaction';

export enum StationNetwork {
  Online = 'Online',
  Disconnected = 'Disconnected',
}

export enum StationMode {
  Normal = 'Normal',
  Maintenance = 'Maintenance',
  Diagnostic = 'Diagnostic',
}

export enum StationStatus {
  Ready = 'Ready',
  InUse = 'InUse',
  Sleeping = 'Sleeping',  // will remove after discussion
  Resting = 'Resting',
  Disabled = 'Disabled',
  Disconnected = 'Disconnected',
  Diagnostic = 'Diagnostic',
  AssistanceNeeded = 'AssistanceNeeded',
  ShopperAssistance = 'ShopperAssistance',
  Assistance = 'Assistance',
  Maintenance = 'Maintenance',
  RegisteredAwaitingConnection = 'RegisteredAwaitingConnection',
}

export enum StationType {
  Sale = 'Sale',
  Validator = 'Validator',
}

export enum PosType {
  Appetize = 'Appetize',
  BestRing = 'BestRing',
  Centarro = 'Centarro',
  Cueto = 'Cueto',
  Demo = 'Demo',
  NSPOS = 'NSPOS',
  OpSuite = 'OpSuite',
  RetailCloud = 'RetailCloud',
  RetailPro = 'RetailPro',
  Square = 'Square',
  SumUp = 'SumUp',
  TeamworkCommerce = 'TeamworkCommerce'
}
export interface Station {
  id: number;
  permanentId?: string;
  macAddress?: string;
  stationType?: string;
  name: string;
  network?: StationNetwork;
  operatingMode?: StationMode;
  status?: StationStatus;
  alert?: Alert;
  version?: string;
  agentSoftwareVersion?: string;
  installationDate?: string;
  lastUpdateDate?: string;
  currentMemory?: string;
  antenna?: number;
  stationGroup: StationGroup;
  stationGroupId?: string;
  CreatedOn?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  note?: string;
  currentOrderId?: string;
  pos?: string;
  posApiKey?: string;
  shortCode?: string;
  configurationProfileId?: number;
  rfidHardwareStatus?: string;
  machineName?: string;
  currentOrder?: any;
  readerHealthStatus?: any[];
}

export interface StationsList {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: Station[],
  pageSize: number,
  skip: number,
}

// export enum StationRFIDStatus {
//   Connected = 'Connected',
//   Disconnected = 'Disconnected',
// }

export enum StationHealthType {
  highCPU = 'High CPU',
  highMemory = 'High Memory',
}

export interface StationHealth {
  id: number,
  cpu: number,
  memory: number,
  systemCpu: number,
  availableMemory: number,
  createdOn: string,
  stationName: string,
  stationId: number,
  status?: StationStatus,
  broadCastMessage: string,
  healthType?: StationHealthType;
}
export interface ReaderStatus {
  name?: string,
  status?: StationRFIDReaderStatus,
}

export enum StationRFIDStatus {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

export enum StationRFIDReaderStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Connected = 'Connected',
  Starting = 'Starting',
  Listening = 'Listening',
  Stopping = 'Stopping',
  Stopped = 'Stopped',
  Pausing = 'Pausing',
  Paused = 'Paused',
  Resuming = 'Resuming',
  Disconnected = 'Disconnected',
  UnknownReader = 'UnknownReader',
}

export enum RFIDReaderStatusToDisplay {
  Scanning = 'Scanning',
  Ready = 'Ready to Add More Items',
  Stopped = 'Stopped',
  Disabled = 'Disabled',
  Disconnected = 'Disconnected',
  UnknownReader = 'Unknown Reader',
}

export type StationLogLevel =
  | 'Debug'
  | 'Information'
  | 'Warning'
  | 'Error'
  | 'Fatal';

export interface StationSummary {
  id: number;
  stationId?: number;
  dateTime?: string;
  totalItems: StationSummaryItem;
  totalOrders: StationSummaryItem;
  readsAverage: StationSummaryItem; // in seconds.
}

export interface LatestStationHealth {
  id: number;
  cpu: number;
  memory: number;
  availableMemory: number;
  threadCount: number;
  readerStatus?: ReaderStatus[];
}
export interface TransactionSummary {
  completedTransactionCount: number;
  averagePurchasedItems: number;
  averageScanDuration: number;
  averagePaymentDuration: number;
  averageTransactionDuration: number;
  averageItems: number;
}
export interface StationSummaryItem {
  count: number;
  deltaDirection: StationDeltaDirection;
  deltaDifference: number;
}
export interface TransactionSummaryItem {
  count: number;
  deltaDirection: boolean;
  deltaDifference: number;
}

export enum StationDeltaDirection {
  up = 'up',
  down = 'down',
}
export interface StationHistory {
  id: number,
  stationId: number,
  timestamp: string,
  cpu: string,
  threads: string,
  memory: string,
  status: StationStatus,
}
export interface StationLog {
  id: number,
  stationId: number,
  timestamp: string,
  logLevel: StationLogLevel,
  logText: string,
}
export interface StationActivities {
  id: number,
  modifiedOn: string,
  modifiedBy: string,
  stationName: string,
  status: TransactionStatus,
  itemCount: number,
}
export interface StationReads {
  data?: string | number[],
  labels?: string | number[],
  timezone?: string
}
export interface StatusDataType {
  name: string;
  text: string;
  textColor: string;
  color: string;
}

export interface StationMessage {
  dateTime: string;
  level: MessageLevel;
  message: string;
  permanentId: string;
}

export interface StationsCount {
  status: string;
  count: number;
}

export interface StationHealthsRequestPayload {
  stationId: number,
  lastStationHealthId?: number | null,
  limit: number,
}

export enum CaptureMethod {
  Detected = 'Detected',
  ManuallyAdded = 'ManuallyAdded'
}