import { useEffect } from 'react';
import { StationMessage } from 'src/types/station';
import { getSelectedClientId } from 'src/utils/client';
import browserNotification from '../utils/browserNotification';
import useAuth from './useAuth';
import { appInsights,severityLevel} from 'src/utils/appInsight';

const useSignalRNotificationsListener = (signalRConnection, startConnection = false, isAuthenticated = false, setStationToastMessage): null => {
  const { user } = useAuth();
  useEffect(() => {
    if (signalRConnection?.connectionState === 'Disconnected' && startConnection && isAuthenticated) {
      signalRConnection.start()
        .then(async () => {
          await signalRConnection.send('RegisterManagementConsoleAsync', user.id, getSelectedClientId());
          signalRConnection.on('SendPushNotificationToManagementConsoleAsync', (notification: Notification) => {
            browserNotification(notification);
            appInsights.trackTrace({ message: 'signalR : Listening to SendPushNotificationToManagementConsoleAsync', properties:{'notification': notification}, severityLevel: severityLevel.Information });
          });
          signalRConnection.on('SendStationMessageAsync', (stationMessage: StationMessage) => {
            setStationToastMessage(stationMessage);
            appInsights.trackTrace({ message: 'signalR : Listening to SendStationMessageAsync', properties:{'stationMessage': stationMessage}, severityLevel: severityLevel.Information });
          });
        })
        .catch(error => {
          console.log('SignalR Connection failed: ', error);
          appInsights.trackException({ exception: error, severityLevel: severityLevel.Error });
        });
      return () => {
        signalRConnection.off('SendPushNotificationToManagementConsoleAsync');
        signalRConnection.stop();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRConnection, startConnection, isAuthenticated]);
  return null;
};

export default useSignalRNotificationsListener;
