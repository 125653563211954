import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorDetails = {
      status: error.response?.status,
      data: error.response?.data,
      message: error.response?.data || 'Something went wrong'
    };
    console.log('Axios interceptor error:', errorDetails);
    return Promise.reject(errorDetails);
  }
);

export default axiosInstance;
