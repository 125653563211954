/* eslint-disable no-unused-vars */

export interface SubscribedUser {
    id: number;
    superAdmin: string,
    clientAdmin: string,
    storeManager: string,
    floorManager: string,
    clerk: string,
    alertType: string;
}

export interface AlertNotification {
    id: number;
    event: string;
    alertType: string[];
    enable: boolean;
    users: SubscribedUser[];
}

export enum ProductImportStatus {
    None = 'None',
    Completed = 'Completed',
    Failed = 'Failed',
    Initiated = 'Initiated',
    InitialValidation = 'InitialValidation',
    Queued = 'Queued',
    Canceled = 'Canceled',
    SecondaryValidation = 'SecondaryValidation',
    SavingData = 'SavingData',
    SavingResults = 'SavingResults',
    FetchingFromPos = 'FetchingFromPos',
    TransformingData = 'TransformingData',
    Aborted = 'Aborted'
}