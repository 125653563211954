/* eslint-disable no-unused-vars */
import { Store } from './store';

export interface User {
  id: number;
  firstName?: string;
  lastName?: string;
  userRoles?: UserRoles[];
  email: string;
  isActive?: boolean;
  userStore?: Store[];
  [key: string]: any;
}

export interface UserInterface { // <==== This is not correct interface.
  id: number;
  avatar?: string;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number | string;
  status: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum UserRoles {
  superAdmin = 'Super Admin',
  clientAdmin = 'Client Admin',
  storeManager = 'Store Manager',
  floorManager = 'Floor Manager',
  tunnelManager = 'Tunnel Manager',
  clerk = 'Clerk',
  actAsInternalService = 'Act As Internal Service',
  actAsService = 'Act As Service'
}