import moment from 'moment';
import { CaptureMethod } from 'src/types/station';
import { UnknownItems } from 'src/types/unknownItems';
import mock from 'src/utils/mock';

const rows: UnknownItems[] = [
  {
    id: '5ece2c077e39da27658aa8a9',
    primaryIdentifier: 'abc123',
    upc: 'abc',
    stationName: 'Cap',
    receivedTime: moment()
      .subtract(6, 'hours')
      .toDate()
      .getTime(),
    externalId: '123',
    referenceNumber: '',
    captureMethod: CaptureMethod.Detected,
    itemStatus: 'Voided',
  },
  {
    id: '5ece2c077e39da27658aa8a9',
    primaryIdentifier: 'abc123',
    upc: 'abc',
    stationName: 'Cap',
    receivedTime: moment()
      .subtract(6, 'hours')
      .toDate()
      .getTime(),
    externalId: '123',
    referenceNumber: '',
    captureMethod: CaptureMethod.ManuallyAdded,
    itemStatus: 'Voided',
  },
  {
    id: '5ece2c077e39da27658aa8a9',
    primaryIdentifier: 'abc123',
    upc: 'abc',
    stationName: 'Cap',
    receivedTime: moment()
      .subtract(6, 'hours')
      .toDate()
      .getTime(),
    externalId: '123',
    referenceNumber: '',
    captureMethod: CaptureMethod.ManuallyAdded,
    itemStatus: 'Voided',
  },
];

const data = {
  'page': 1,
  'totalPages': 1,
  'totalCount': 3,
  rows,
  'pageSize': 10,
  'skip': 0,
};

mock.onGet('/api/UnknownItems').reply(200, data);
