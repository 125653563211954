import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';
import { appInsights } from 'src/utils/appInsight';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
  customs?: any;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
  customs?: any;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.EXO_DARK,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#828382'
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: '#353635'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#212221'
      },
      secondary: {
        main: '#353635'
      },
      text: {
        primary: '#212221',
        secondary: '#353635'
      }
    },
    shadows: strongShadows,
    customs: {
      theme: '#282C34',
      stationAssistanceColor: '#e40000',
      stationOnlineColor: '#006400',
      station: {
        red: '#ff3333',
        yellow: '#edb100',
        green: '#5da000',
        grey: '#AEAEAE',
        blue: '#1f58ad',
        darkBlue: '#163E7B',  //Resting
        purple: '#d358d3',   // Diagnostic, Mantenance
        lightRed: '#ff4d4d'
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',

      transaction: {
        peachOrange: 'hsla(35, 100%, 92%, 0.5)',
        orange: 'hsla(36, 100%, 78%, 0.5)',
        whisper: '#EEEEEE',
        lightGreen: 'hsla(102, 100%, 93%, 0.5)',
        green: 'hsla(102, 55%, 73%, 0.5)',
        lightRed: 'hsla(360, 75%, 54%, 0.75)',
        red: 'hsla(360, 98%, 38%, 1.00)',
      },
      loader: {
        background: '#C7C7C7',
        foreground: '#f4f6f8',
        nProgress: '#C7C7C7',
      },
      scheduler: {
        active: '#FFFFFF',
        selected: 'rgba(33, 150, 243, 0.15)',
        selectedBorder: 'rgba(33, 150, 243, 0.4)',
        hover: 'rgba(33, 150, 243, 0.10)',
        current: '#AEAEAE',
        time: {
          start: '#7bb762',
          end: '#163E7B',
        }
      }
    },
  },
  {
    name: THEMES.EXO_LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#212221'
      },
      secondary: {
        main: '#80C41C'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    customs: {
      theme: '#FFFFFF',
      stationAssistanceColor: '#e40000',
      stationOnlineColor: '#006400',
      station: {
        red: '#ff3333',
        yellow: '#edb100',
        green: '#5da000',
        grey: '#AEAEAE',
        blue: '#1f58ad',
        darkBlue: '#163E7B',  //Resting
        purple: '#d358d3',   // Diagnostic, Mantenance
        lightRed: '#ff4d4d'
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      transaction: {
        peachOrange: 'hsla(35, 100%, 92%, 0.5)',
        orange: 'hsla(36, 100%, 78%, 0.5)',
        whisper: '#EEEEEE',
        lightGreen: 'hsla(102, 100%, 93%, 0.5)',
        green: 'hsla(102, 55%, 73%, 0.5)',
        lightRed: 'hsla(360, 75%, 54%, 0.75)',
        red: 'hsla(360, 98%, 38%, 1.00)',
      },
      loader: {
        background: '#C7C7C7',
        foreground: '#f4f6f8',
        nProgress: '#80C41C',
      },
      scheduler: {
        active: '#FFFFFF',
        selected: 'rgba(33, 150, 243, 0.15)',
        selectedBorder: 'rgba(33, 150, 243, 0.4)',
        hover: 'rgba(33, 150, 243, 0.10)',
        current: '#f0ffdb',
        time: {
          start: '#7bb762',
          end: '#163E7B',
        }
      }
    },
  },
  {
    name: THEMES.EXO_BLUE,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#163E7B'
      },
      secondary: {
        main: '#1f58ad'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    customs: {
      theme: '#163E7B',
      stationAssistanceColor: '#e40000',
      stationOnlineColor: '#006400',
      station: {
        red: '#ff3333',
        yellow: '#edb100',
        green: '#5da000',
        grey: '#AEAEAE',
        blue: '#1f58ad',
        darkBlue: '#163E7B',  //Resting
        purple: '#d358d3',   // Diagnostic, Mantenance
        lightRed: '#ff4d4d'
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      transaction: {
        peachOrange: 'hsla(35, 100%, 92%, 0.5)',
        orange: 'hsla(36, 100%, 78%, 0.5)',
        whisper: '#EEEEEE',
        lightGreen: 'hsla(102, 100%, 93%, 0.5)',
        green: 'hsla(102, 55%, 73%, 0.5)',
        lightRed: 'hsla(360, 75%, 54%, 0.75)',
        red: 'hsla(360, 98%, 38%, 1.00)',
      },
      loader: {
        background: '#C7C7C7',
        foreground: '#f4f6f8',
        nProgress: '#f4f6f8'
      },
      scheduler: {
        active: '#FFFFFF',
        selected: 'rgba(33, 150, 243, 0.15)',
        selectedBorder: 'rgba(33, 150, 243, 0.4)',
        hover: 'rgba(33, 150, 243, 0.10)',
        current: '#d6e6ff',
        time: {
          start: '#7bb762',
          end: '#163E7B',
        }
      }
    }
  },
  {
    name: THEMES.DARK_MODE,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#80C41C'
      },
      secondary: {
        main: '#80C41C'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows,
    customs: {
      theme: '#282C34',
      stationAssistanceColor: '#ff0000',
      stationOnlineColor: '#39ff14',
      station: {
        red: '#ff3333',
        yellow: '#EFCE67',
        green: '#A5D562',
        grey: '#AEAEAE',
        blue: '#3f8cff',
        darkBlue: '#2d6dce',  //Resting
        purple: '#CCA1CC',   // Diagnostic, Maintenance
        lightRed: '#ff4d4d'
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      transaction: {
        peachOrange: 'hsla(35, 100%, 35%, 0.3)',
        orange: 'hsla(33, 100%, 25%, 0.3)',
        whisper: 'rgba(255, 255, 255, 0.04)',
        lightGreen: 'hsla(103, 100%, 15%, 0.5)',
        green: 'hsla(103, 100%, 10%, 0.5)',
        lightRed: 'hsla(360, 75%, 54%, 0.75)',
        red: 'hsla(360, 98%, 38%, 1.00)',
      },
      scheduler: {
        active: '#282C34',
        selected: 'rgba(33, 150, 243, 0.15)',
        selectedBorder: 'rgba(33, 150, 243, 0.4)',
        hover: 'rgba(33, 150, 243, 0.10)',
        current: '#393d47',
        time: {
          start: '#7bb762',
          end: '#163E7B',
        }
      },
      loader: {
        background: '#ADB0BB',
        foreground: '#E6E5E8',
        nProgress: '#f4f6f8'
      }
    }
  },
];

export const createTheme = (config: ThemeConfig = {}): Theme => {

    let themeOptions = themesOptions.find((theme) => theme.name === config.theme);
    appInsights.trackEvent({ name: 'Theme updated', properties: { 'name': config.theme } });

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction },
      {
        overrides: {
          MuiTableRow: {
            hover: {
              '&:hover': {
                backgroundColor: 'rgba(33, 150, 243, 0.15) !important'
              }
            }
          }
        }
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
