const refreshTokenConstant = 'refreshToken';
const accessTokenConstant = 'accessToken';
const getLocalRefreshToken = () => {
  const localRefreshToken = localStorage.getItem(refreshTokenConstant);
  return localRefreshToken;
};
const getLocalAccessToken = () => {
  const localAcessToken = localStorage.getItem(accessTokenConstant);
  return localAcessToken;
};
const updateLocalAccessToken = (token) => {
  localStorage.setItem(accessTokenConstant, token);
};
const updateLocalRefreshToken = (token) => {
  localStorage.setItem(refreshTokenConstant, token);
};
const logOut = () => {
  localStorage.removeItem(accessTokenConstant);
  localStorage.removeItem(refreshTokenConstant);
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
  logOut,
};
export default TokenService;
