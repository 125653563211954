const getUrlParams = (params:any): string => {
  let urlParams = '';
  for (const key in params) {
    if (params.hasOwnProperty(key) && params[key]) {
      urlParams += `${key}=${typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key]}&`;
    }
  }
  return encodeURI(urlParams.substring(0, urlParams.length - 1));
};
export default getUrlParams;
