import { useEffect } from 'react';

const useSignalRProductImportStatusListener = (
  signalRConnection,
  clientId,
  setActiveNotification,
  startConnection = false,
  isUserAuthenticated = false
): null => {
  useEffect(() => {
    if (
      signalRConnection?.connectionState === 'Disconnected' &&
      startConnection &&
      isUserAuthenticated &&
      clientId
    ) {
      const startSendAndSubscribe = async () => {
        await signalRConnection
          .start()
          .then(() => console.log('connection started'))
          .catch(error => console.log('product-import signalr connection', error));

        await signalRConnection.send('RegisterAsync', clientId);

        signalRConnection.on(
          'ProductImportStatusAsync',
          (productImportStatus: any) => {
            setActiveNotification(productImportStatus);
            console.log(productImportStatus);
          }
        );
      };
      startSendAndSubscribe();
    }
    return () => {
      if (
        signalRConnection?.connectionState === 'Connected' &&
        startConnection &&
        isUserAuthenticated &&
        clientId
      ) {
        signalRConnection.off('RegisterAsync');
        signalRConnection.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRConnection, startConnection, isUserAuthenticated, clientId]);
  return null;
};

export default useSignalRProductImportStatusListener;
