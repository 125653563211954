import { ProductImportStatus } from 'src/types/alertNotification';
import { ImportedFiles } from 'src/types/product';
export const getProductObjForPostAPI = (data: any) => {
  return {
    reference: data.reference,
    name: data.name,
    type: data.type,
    icon: data.icon || null,
    description: data.description || null,
    sku: data.sku,
    externalId: data.externalId,
    upc: data.upc,
    productUpcs: data.productUpcs,
    epcPrefix: data.epcPrefix,
    imageUrl: data.imageUrl || null,
    notes: data.notes || null,
    color: data.color,
    size: data.size,
    price: data.price,
    isActive: data.isActive === 'true' ? true : false,
  };
};

export const getImportedFiles = (data: ImportedFiles) => {
  if (Array.isArray(data)) {
    return data.map(item => item.fileId);
  }
};

export const getProductImportStatusDescription = (status: string): { finished: boolean, description: string, variant: any } => {

  if (status == ProductImportStatus.Initiated) {
    console.log('Import initiated')
    return { finished: false, description: 'Product import initiated', variant: 'info' }
  }

  if (status == ProductImportStatus.InitialValidation || status == ProductImportStatus.SecondaryValidation) {
    return { finished: false, description: 'Product import in validation', variant: 'info' }
  }

  if (status == ProductImportStatus.FetchingFromPos) {
    return { finished: false, description: 'Product import - fetching from pos', variant: 'info' };
  }

  if (status == ProductImportStatus.TransformingData) {
    return { finished: false, description: 'Product import - transforming data', variant: 'info' };
  }

  if (status == ProductImportStatus.Queued) {
    return { finished: false, description: 'Product import - queued', variant: 'info' };
  }

  if (status == ProductImportStatus.SavingData || status == ProductImportStatus.SavingResults) {
    return { finished: false, description: 'Product import - saving', variant: 'info' };
  }

  if (status == ProductImportStatus.Canceled) {
    return { finished: true, description: 'Product import - canceled', variant: 'success' };
  }
  if (status == ProductImportStatus.Completed) {
    return { finished: true, description: 'Product import - completed', variant: 'success' };
  }
  if (status == ProductImportStatus.Failed) {
    return { finished: true, description: 'Product import - failed', variant: 'error' };
  }
  if (status == ProductImportStatus.Aborted) {
    console.log('Import aborted')
    return { finished: true, description: 'Product import - aborted', variant: 'error' };
  }
}
