import type { AppThunk } from 'src/store';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { pascalCase } from 'change-case';
import { OperatingSchedule as OperatingScheduleInterface } from 'src/types/operatingSchedule';
import genericActions, { emptyListingObject, genericMockActions } from './common';
import NProgress from 'nprogress';
import { removeHTML } from 'src/utils/common';
import { api } from 'src/utils/api';

export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: OperatingScheduleInterface[],
  pageSize: number,
  skip: number,
}
export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  recordsForDropdown?: ReduxStoreListObject;
  selectedRecord?: OperatingScheduleInterface;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = {
  id: 0,
  startTime: '',
  endTime: '',
};
const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  recordsForDropdown: emptyListingObject,
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'operatingSchedule',
  initialState,
  reducers: {
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, error, message, loading } = action.payload;
      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }

    },
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;
      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    set(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, success, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.success = success;
        state.message = message;
        state.actionType = 'set';
      }
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxStoreObject) {
      state.records = emptyListingObject;
    },
  }
});
export const reducer = slice.reducer;
const resource = 'OperatingSchedules';
export const { put, get, patch, resetError, resetSuccess } = genericActions(slice.actions, 'operatingSchedules/store', emptyObject);
export const { getAll: getAllMock } = genericMockActions(slice.actions, 'OperatingSchedules', emptyObject);
export const set = (id: number, obj: any): AppThunk => async (dispatch) => {
  NProgress.start();

  const { status, data } = await api(`${resource}/store/${id}`, 'post', obj);

  const getData = (data, error, message, success): ReduxStoreObject => ({
    selectedRecord: data,
    success,
    error,
    message,
    loading: false,
  });

  if (status === 200) {
    dispatch(slice.actions.set(getData(data, false, 'Operating Schedule set successfully', true)));
  } else {

    let msg = `An error while updating a ${pascalCase(resource)}!`;
    if (data.detail)
      msg = `${removeHTML(data.detail)}`;
    dispatch(slice.actions.get(getData([], true, msg, false)));
  }
  NProgress.done();
};

export default slice;