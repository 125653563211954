import mock from 'src/utils/mock';
import { Station, StationLog, StationHealthType, StationHealth, StationRFIDReaderStatus, StationHistory, StationsCount, StationMode, StationSummary, StationDeltaDirection, StationStatus, LatestStationHealth } from 'src/types/station';
import { layout } from 'src/types/stationGroups';
import moment from 'moment';

mock.onGet('/api/stations').reply(() => {
  const data: Station[] = [
    {
      'name': 'ANI-Register-1',
      'id': 3,
      'stationGroup': {
        'id': 2,
        'name': 'Station Group 1',
        'locationDescription': '',
        'storeId': 1,
        'layout': layout.horizontal,
        'store': {
          'id': 1,
          'name': 'test1',
          'storeUniqueId': 'ABCDEF',
          'email': 'andrew@gmail.com',
          'phone': '123456789',
          'contactName': 'Andrew Rutter',
          'notes': '<p>Test</p>',
          'clientId': 1,
          'address': {
            'address1': 'Address 1',
            'address2': 'Address 2',
            'city': 'abc',
            'state': 'GA',
            'zip': '30004'
          }
        }
      },
      'modifiedOn': '2021-05-26T14:17:26.844838+00:00',
      'modifiedBy': '6d641536-4e34-475b-b10f-70cd1e1e0697',
      'operatingMode': StationMode.Normal,
      'status': StationStatus.InUse,
      'shortCode': 'XYZ123'
    },
    {
      'name': 'Delta-Club-1',
      'id': 6,
      'stationGroup': {
        'id': 2,
        'name': 'Station Group 1',
        'locationDescription': '',
        'storeId': 1,
        'layout': layout.horizontal,
        'store': {
          'id': 1,
          'name': 'test1',
          'storeUniqueId': 'ABCDEF',
          'email': 'andrew@gmail.com',
          'phone': '123456789',
          'contactName': 'Andrew Rutter',
          'notes': '<p>Test 123</p>',
          'clientId': 1,
          'address': {
            'address1': 'Address 1',
            'address2': 'Address 2',
            'city': 'abc',
            'state': 'GA',
            'zip': '30004'
          }
        }
      },
      'modifiedOn': '2021-05-18T09:03:24.4053776+00:00',
      'modifiedBy': '6d641536-4e34-475b-b10f-70cd1e1e0697',
      'operatingMode': StationMode.Normal,
      'status': StationStatus.InUse,
      'shortCode': ''
    },
    {
      'name': 'EXO-Go-Lab-1',
      'id': 4,
      'stationGroup': {
        'id': 2,
        'name': 'Station Group 1',
        'locationDescription': '',
        'storeId': 1,
        'layout': layout.horizontal,
        'store': {
          'id': 1,
          'name': 'test1',
          'storeUniqueId': 'ABCDEF',
          'email': 'andrew@gmail.com',
          'phone': '123456789',
          'contactName': 'Andrew Rutter',
          'notes': '<p>Hello 34567</p>',
          'clientId': 1,
          'address': {
            'address1': 'Address 1',
            'address2': 'Address 2',
            'city': 'abc',
            'state': 'GA',
            'zip': '30004'
          }
        }
      },
      'modifiedOn': '2021-05-13T08:20:23.6130658+00:00',
      'modifiedBy': '6d641536-4e34-475b-b10f-70cd1e1e0697',
      'operatingMode': StationMode.Normal,
      'status': StationStatus.InUse,
      'shortCode': ''
    },
    {
      'name': 'Register-1',
      'id': 2,
      'stationGroup': {
        'id': 2,
        'name': 'Station Group 1',
        'locationDescription': '',
        'storeId': 1,
        'layout': layout.square,
        'store': {
          'id': 1,
          'name': 'test1',
          'storeUniqueId': 'ABCDEF',
          'email': 'andrew@gmail.com',
          'phone': '123456789',
          'contactName': 'Andrew Rutter',
          'notes': '<p>Hi ... 123</p>',
          'clientId': 1,
          'address': {
            'address1': 'Address 1',
            'address2': 'Address 2',
            'city': 'abc',
            'state': 'GA',
            'zip': '30004'
          }
        }
      },
      'modifiedOn': '2021-05-28T05:02:16.4633017+00:00',
      'modifiedBy': '',
      'operatingMode': StationMode.Normal,
      'status': StationStatus.InUse,
      'shortCode': ''
    }
  ];

  return [200, data];

});

mock.onGet('/api/stations/1').reply(() => {
  const data: Station = {
    id: 1,
    macAddress: '51-7F-69-B7-20-43',
    name: 'Clone-I',
    status: StationStatus.Ready,
    operatingMode: StationMode.Normal,
    agentSoftwareVersion: '1.0.7',
    installationDate: '2021-01-01',
    lastUpdateDate: '2021-02-01',
    currentMemory: '16 GB',
    antenna: 4,
    stationGroup: {
      id: 1,
      name: 'Front Station Group',
      locationDescription: 'Hall 1 first floor',
      layout: layout.square,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  };

  return [200, data];

});

mock.onGet('/api/stations/2').reply(() => {
  const data: Station = {
    id: 2,
    macAddress: '9D-A6-43-47-F5-BB',
    name: 'Ramp Aria',
    status: StationStatus.Ready,
    operatingMode: StationMode.Normal,
    agentSoftwareVersion: '1.0.7',
    installationDate: '2021-01-01',
    lastUpdateDate: '2021-02-01',
    currentMemory: '16 GB',
    antenna: 4,
    stationGroup: {
      id: 1,
      name: 'Front Station Group',
      locationDescription: 'Hall 1 first floor',
      layout: layout.square,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  };

  return [200, data];
});

mock.onGet('/api/stations/3').reply(() => {
  const data: Station = {
    id: 3,
    macAddress: 'FE-78-29-E1-A2-D0',
    name: 'Am Ant Ash',
    status: StationStatus.Ready,
    operatingMode: StationMode.Normal,
    agentSoftwareVersion: '1.0.6',
    installationDate: '2021-01-01',
    lastUpdateDate: '2021-02-01',
    currentMemory: '12 GB',
    antenna: 2,
    stationGroup: {
      id: 1,
      name: 'Front Station Group',
      locationDescription: 'Hall 1 first floor',
      layout: layout.square,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  };

  return [200, data];
});

mock.onGet('/api/stations/1/summary').reply(() => {
  const data: StationSummary = {
    id: 1,
    stationId: 1,
    dateTime: moment()
      .subtract(32, 'minutes')
      .format('YYYY-MM-DD HH:mm:ss'),
    totalItems: {
      count: 2,
      deltaDirection: StationDeltaDirection.up,
      deltaDifference: 4
    },
    totalOrders: {
      count: 4,
      deltaDirection: StationDeltaDirection.up,
      deltaDifference: 3
    },
    readsAverage: {
      count: 20, // In Seconds
      deltaDirection: StationDeltaDirection.up,
      deltaDifference: 2
    },
  };

  return [200, data];
});

mock.onGet('/api/stations/1/history').reply(() => {
  const data: StationHistory[] = [
    {
      id: 1,
      stationId: 1,
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
    {
      id: 2,
      stationId: 1,
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
  ];

  return [200, data];
});

mock.onGet('/api/stations/count').reply(() => {
  const data: StationsCount[] = [
    {
      status: 'Ready',
      count: 40,
    },
    {
      status: 'InUse',
      count: 20,
    }, {
      status: 'RegisteredAwaitingConnection',
      count: 10,
    },
    {
      status: 'AssistanceNeeded',
      count: 5,
    },
    {
      status: 'Disabled',
      count: 5,
    }
  ];

  return [200, data];
});

mock.onGet('/api/StationHealth').reply(() => {
  const data: StationHealth[] = [
    {
      id: 1,
      cpu: 2.32,
      memory: 4011932,
      systemCpu: 30.0,
      availableMemory: 1908692,
      createdOn: '2021-11-02T07:27:10.0987163+00:00',
      stationName: 'Station One',
      stationId: 1,
      status: StationStatus.InUse,
      broadCastMessage: 'string',
      healthType: StationHealthType.highCPU
    },
    {
      id: 2,
      cpu: 24.0,
      memory: 4011932,
      systemCpu: 30.0,
      availableMemory: 1808692,
      createdOn: '2021-11-02T07:27:10.0987163+00:00',
      stationName: 'Station Two',
      stationId: 1,
      status: StationStatus.Disconnected,
      broadCastMessage: 'string',
      healthType: StationHealthType.highCPU
    },
    {
      id: 3,
      cpu: 8.0,
      memory: 4011932,
      systemCpu: 30.0,
      availableMemory: 3808692,
      createdOn: '2021-11-02T07:27:10.0987163+00:00',
      stationName: 'Station Three',
      stationId: 1,
      status: StationStatus.Ready,
      broadCastMessage: 'string',
      healthType: StationHealthType.highMemory
    },
    {
      id: 4,
      cpu: 28.0,
      memory: 4011932,
      systemCpu: 30.0,
      availableMemory: 2808692,
      createdOn: '2021-11-02T07:27:10.0987163+00:00',
      stationName: 'Station Four',
      stationId: 1,
      status: StationStatus.Ready,
      broadCastMessage: 'string',
      healthType: StationHealthType.highMemory
    }
  ];

  return [200, data];
});

mock.onGet('/api/stations/1/logs').reply(() => {
  const data: StationLog[] = [
    {
      id: 1,
      stationId: 1,
      timestamp: '2013-07-22 12:50:05',
      logLevel: 'Debug',
      logText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    },
  ];

  return [200, data];
});

mock.onGet('/api/Stations/1/LatestStationHealth').reply(() => {
  const data: LatestStationHealth = {
    id: 4,
    cpu: 28.0,
    memory: 4011932,
    availableMemory: 2808692,
    threadCount: 0,
    readerStatus: [{
      name: 'Test', // In Seconds
      status: StationRFIDReaderStatus.Listening,
    }],
  };

  return [200, data];
});