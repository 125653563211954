import React from 'react';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { getSelectedClientId } from 'src/utils/client';
import useCheckRole from '../hooks/useCheckRole';
import { UserRoles } from '../types/user';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {

  const { isAuthenticated } = useAuth();
  const { user } = useAuth();
  const istunnelManager = useCheckRole(UserRoles.tunnelManager, user);
  
  if (istunnelManager && isAuthenticated && getSelectedClientId()) {
    return <Navigate to="/app/reports/tunnel" />;
  }

  if (isAuthenticated && !getSelectedClientId()) {
    return <Navigate to="/choose-client" />;
  } else if (isAuthenticated && getSelectedClientId()) {
    return <Navigate to="/app/reports/dashboard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
