import type { AppThunk } from 'src/store';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { StationHealth, StationsCount } from 'src/types/station';
import { emptyListingObject } from './common';
import { api } from 'src/utils/api';
import NProgress from 'nprogress';

export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: StationHealth[],
  pageSize: number,
  skip: number,
}

export interface ReduxStoreObject {
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
  count?: StationsCount[];
  healths?: ReduxStoreListObject;
}

const emptyStationsCount = [];
const initialState: ReduxStoreObject = {
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
  count: emptyStationsCount,
  healths: emptyListingObject,
};

const slice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    getCount(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { count, error, message, loading } = action.payload;
      state.count = count;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getCount';
    },
    getHealths(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { healths, error, message, loading } = action.payload;
      state.healths = healths;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getHealths';
    }
  }
});

export const reducer = slice.reducer;
export const getStationCount = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { status, data } = await api('Stations/count', 'get', params);

  const getData = (data, success, error, message): ReduxStoreObject => ({
    count: data,
    success,
    error,
    message,
    loading: false,
  });
  if (status === 200)
    dispatch(slice.actions.getCount(getData(data, false, '', true)));
  else
    dispatch(slice.actions.getCount(getData([], true, 'An error while loading station count!', false)));

  NProgress.done();
};

export const getStationHealth = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { status, data } = await api('StationHealth', 'get', params);
  const getData = (data, success, error, message): ReduxStoreObject => ({
    healths: data,
    success,
    error,
    message,
    loading: false,
  });
  if (status === 200) {
    dispatch(slice.actions.getHealths(getData(data, false, '', true)));
  } else {
    dispatch(slice.actions.getHealths(getData([], true, 'An error while loading station health!', false)));
  }
  NProgress.done();
};
export default slice;