/* eslint-disable  react-hooks/exhaustive-deps*/
import * as signalR from '@microsoft/signalr';
import React, { createContext, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { appInsights,severityLevel} from 'src/utils/appInsight';
import { CustomHttpClient } from 'src/utils/customHttpClient';

interface SocketContextInterface {
	activeConnection: any | null;
}

interface SocketProviderInterface {
	children: ReactNode;
}

const SocketContext = createContext<SocketContextInterface>({
	activeConnection: {}
});

export const SocketProvider: FC<SocketProviderInterface> = ({ children }) => {
	const [activeConnection, setActiveConnection] = useState(null);
	const { isAuthenticated } = useAuth();
	const connectionStatus = activeConnection?.connection?.connectionState;

	const emitSocketEvent = async () => {
		await activeConnection.start();
	};

	const initConnection = useCallback(() => {
		const accessToken: string = localStorage.getItem('accessToken');
		if (accessToken) {
			try {
				const makeConnection = new signalR.HubConnectionBuilder()
					.withUrl(`${process.env.REACT_APP_SOCKET_URL}stationsHub`, { accessTokenFactory: () => localStorage.getItem('accessToken'), httpClient: new CustomHttpClient() })
					.withAutomaticReconnect({
						nextRetryDelayInMilliseconds: retryContext => {
							if (retryContext.previousRetryCount <= 10) {
								return 5000;
							} else {
								return 10000;
							}
						}
					})
					.build();
				setActiveConnection(makeConnection);
				appInsights.trackTrace({ message:'SignalR : InitConnection called', properties:{'connectionDetails': makeConnection}, severityLevel: severityLevel.Information });
			} catch (e) {
				appInsights.trackException({ exception: e, severityLevel: severityLevel.Error });
				return false;
			}
		} else {
			return false;
		}
	}, [useIsMountedRef]);

	const disconnectSocket = () => {
		activeConnection.off('StationAsync');
		activeConnection.stop();
		setActiveConnection(null);
	};

	useEffect(() => {
		if (!activeConnection && isAuthenticated) {
			initConnection();
		}
		if (activeConnection) {
			return () => {
				disconnectSocket();
			};
		}
	}, [activeConnection, isAuthenticated]);

	useEffect(() => {
		if (connectionStatus === 'Disconnected' && isAuthenticated) {
			emitSocketEvent();
		}
	}, [connectionStatus, isAuthenticated]);

	return (
		<SocketContext.Provider value={{activeConnection}}>
			{children}
		</SocketContext.Provider>
	);
};

export default SocketContext;
