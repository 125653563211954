import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, { Fragment } from 'react';
import { useSelector } from 'src/store';

const UpdateHandler: FC = ({ children }) => {
  const { updated, registration } = useSelector(state => state.serviceWorker);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = key => (
    <Fragment>
      <Box ml={1}>
        <Button size={'small'} variant={'contained'} onClick={(key) => {
          updateServiceWorkerHandler(key);
        }}>
          Update Now
        </Button>
      </Box>
      <Box ml={1}>
        <Button size={'small'} variant={'outlined'} onClick={() => { closeSnackbar(key); }}>
          Dismiss
        </Button>
      </Box>
    </Fragment>
  );

  const updateServiceWorkerHandler = (snackBarId) => {
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    } else {
      closeSnackbar(snackBarId);
    }
  };

  if (updated) {
    enqueueSnackbar('A new version is available.', {
      variant: 'info',
      persist: true,
      key: 'Update available',
      preventDuplicate: true,
      action
    });
  }

  return (
    <>
      {children}
    </>
  );
};

export default UpdateHandler;
