import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Tunnel } from 'src/types/tunnel';
import genericActions, { emptyListingObject } from './common';

export interface ReduxTunnelListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: Tunnel[],
  pageSize: number,
  skip: number,
}
export interface ReduxTunnelObject {
  records?: ReduxTunnelListObject;
  recordsForDropdown?: ReduxTunnelListObject;
  selectedRecord?: Tunnel;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = {
  id: 0,
  permanentId: '',
  name: '',
  locationId: 0,
  notes: '',
  isActive: true,
  isDeleted: true,
  createdOn: '',
  modifiedOn: '',
  createdBy: '',
  modifiedBy: '',
  version: 0,
  tunnelCode: '',
  location: {
    id: 0,
    name: '',
    description: '',
    integrationType: '',
    integrationAPIKey: '',
    timezone: '',
    entryPoint: '',
    notes: '',
    isActive: true,
    isDeleted: true,
    clientId: 0,
    permanentId: '',
    createdOn: '',
    modifiedOn: '',
    createdBy: '',
    modifiedBy: '',
    version: 0,
    client: {
      id: 0,
      organizationName: '',
      logo: '',
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNo: '',
      altPhoneNo: '',
      addressDetails: '',
      notes: '',
      isActive: true,
      sendItemsWithInactiveProductsToPos: true,
      sendItemsWithInvalidProductsToPos: true
    }
  }
};

const initialState: ReduxTunnelObject = {
  records: emptyListingObject,
  recordsForDropdown: emptyListingObject,
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'Tunnel',
  initialState,
  reducers: {
    getAll(state: ReduxTunnelObject, action: PayloadAction<ReduxTunnelObject>) {
      const { records, error, message, loading } = action.payload;
      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    getAllForDropdown(state: ReduxTunnelObject, action: PayloadAction<ReduxTunnelObject>) {
      const { records: clients, error, message, loading } = action.payload;
      state.recordsForDropdown = clients;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAllForDropdown';
    },
    get(state: ReduxTunnelObject, action: PayloadAction<ReduxTunnelObject>) {
      const { selectedRecord, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxTunnelObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxTunnelObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxTunnelObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxTunnelObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxTunnelObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxTunnelObject) {
      state.selectedRecord = emptyObject;
    }
  }
});

export const reducer = slice.reducer;

export const { getAll, getAllForDropdown, get, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, 'Tunnel', emptyObject);

export default slice;