/* eslint-disable no-unused-vars */
export enum Resources {
  clerkDashboard = 'clerkDashboard',
  dashboard = 'dashboard',
  clients = 'clients',
  clientIntegrations='clientIntegrations',
  users = 'users',
  admins = 'admins',
  products = 'products',
  stores = 'stores',
  stationGroups = 'stationGroups',
  stations = 'stations',
  locations='locations',
  globalProfiles = 'globalProfiles',
  alertsAndNotifications = 'alertsAndNotifications',
  operatingSchedules = 'operatingSchedule',
  transactionHistory = 'transactionHistory',
  tunnelHistory = 'tunnelHistory',
  unknownItems = 'unknownItems',
  transaction = 'transaction',
  tunnels='tunnels',
  discounts = 'discounts'
}