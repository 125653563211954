import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ClientIntegration } from 'src/types/clientIntegration';
import genericActions, { emptyListingObject } from './common';

export interface ReduxClientIntegrationListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: ClientIntegration[],
  pageSize: number,
  skip: number,
}
export interface ReduxClientIntegrationObject {
  records?: ReduxClientIntegrationListObject;
  recordsForDropdown?: ReduxClientIntegrationListObject;
  selectedRecord?: ClientIntegration;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const clientIntegrationObject = {
  id: 0,
  clientId: 0,
  isActive: true,
  accessKey: '',
  tokenExpiryDate :'',
  notes:'',
  configData: '',
  topicQueueName: '',
  queueRequestMessage: '',
  importStartTime:'',
  permanentId: '',
  createdOn: '',
  modifiedOn: '',
  createdBy: '',
  modifiedBy: '',
  version: 0
};

const initialState: ReduxClientIntegrationObject = {
  records: emptyListingObject,
  recordsForDropdown: emptyListingObject,
  selectedRecord: clientIntegrationObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'ClientIntegrations',
  initialState,
  reducers: {
    getAll(state: ReduxClientIntegrationObject, action: PayloadAction<ReduxClientIntegrationObject>) {
      const { records, error, message, loading } = action.payload;
      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    getAllForDropdown(state: ReduxClientIntegrationObject, action: PayloadAction<ReduxClientIntegrationObject>) {
      const { records: clients, error, message, loading } = action.payload;
      state.recordsForDropdown = clients;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAllForDropdown';
    },
    get(state: ReduxClientIntegrationObject, action: PayloadAction<ReduxClientIntegrationObject>) {
      const { selectedRecord, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxClientIntegrationObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxClientIntegrationObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxClientIntegrationObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxClientIntegrationObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxClientIntegrationObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxClientIntegrationObject) {
      state.selectedRecord = clientIntegrationObject;
    }
  }
});

export const reducer = slice.reducer;

export const { getAll, getAllForDropdown, get, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, 'ClientIntegrations', clientIntegrationObject);

export default slice;