import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { User as UserInterface } from 'src/types/user';
import genericActions, { emptyListingObject } from './common';
import { api } from 'src/utils/api';
import NProgress from 'nprogress';
import { removeHTML } from 'src/utils/common';
export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: UserInterface[],
  pageSize: number,
  skip: number,
}

export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  selectedRecord?: UserInterface;
  recordsForDropdown?: ReduxStoreListObject;
  recordsForClientAdminDropdown?: ReduxStoreListObject;
  recordsForStoreManagerDropdown?: ReduxStoreListObject;
  recordsForFloorManagerDropdown?: ReduxStoreListObject;
  recordsForClerkDropdown?: ReduxStoreListObject;
  recordsForSuperAdminDropdown?: ReduxStoreListObject;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = {
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  isActive: false,
};

const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  recordsForDropdown: emptyListingObject,
  recordsForSuperAdminDropdown: emptyListingObject,
  recordsForClientAdminDropdown: emptyListingObject,
  recordsForStoreManagerDropdown: emptyListingObject,
  recordsForFloorManagerDropdown: emptyListingObject,
  recordsForClerkDropdown: emptyListingObject,
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    getAllForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.recordsForDropdown = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAllForDropdown';
    },
    getSuperAdminsForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.recordsForSuperAdminDropdown = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getSuperAdminsForDropdown';
    },
    getClientAdminsForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.recordsForClientAdminDropdown = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getClientAdminsForDropdown';
    },
    getClerksForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.recordsForClerkDropdown = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getClientAdminsForDropdown';
    },
    getStoreManagersForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.recordsForStoreManagerDropdown = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getClientAdminsForDropdown';
    },
    getFloorManagersForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.recordsForFloorManagerDropdown = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getClientAdminsForDropdown';
    },
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxStoreObject) {
      state.selectedRecord = emptyObject;
    },
    reset(state: ReduxStoreObject) {
      const { records, recordsForDropdown, recordsForSuperAdminDropdown, recordsForClientAdminDropdown,
        recordsForStoreManagerDropdown, recordsForFloorManagerDropdown, recordsForClerkDropdown,
        selectedRecord, error, success, message, loading, actionType } = initialState;
      state.records = records;
      state.recordsForDropdown = recordsForDropdown;
      state.recordsForSuperAdminDropdown = recordsForSuperAdminDropdown;
      state.recordsForClientAdminDropdown = recordsForClientAdminDropdown;
      state.recordsForStoreManagerDropdown = recordsForStoreManagerDropdown;
      state.recordsForFloorManagerDropdown = recordsForFloorManagerDropdown;
      state.recordsForClerkDropdown = recordsForClerkDropdown;
      state.selectedRecord = selectedRecord;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = actionType;
    },
  }
});

export const reducer = slice.reducer;

export const { getAll, getAllForDropdown, get, set, put, patch, remove, resetError, resetSuccess, reset } = genericActions(slice.actions, 'user', emptyObject);

export const getClientAdminsForDropdown = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { data, status } = await api('user', 'get', params);
  const getData = (data: any, error: boolean, success: boolean, message: string) => ({
    records: data, error, success, message, loading: false,
  });
  if (status === 200)
    dispatch(slice.actions.getClientAdminsForDropdown(getData(data, false, false, '')));
  else {
    let msg = 'An error while loading user!';
    if (data.detail)
      msg = `${removeHTML(data.detail)}`;
    dispatch(slice.actions.getClientAdminsForDropdown(getData(emptyListingObject, true, false, msg)));
  }
  NProgress.done();
};
export const getSuperAdminsForDropdown = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { data, status } = await api('user', 'get', params);
  const getData = (data: any, error: boolean, success: boolean, message: string) => ({
    records: data, error, success, message, loading: false,
  });
  if (status === 200)
    dispatch(slice.actions.getSuperAdminsForDropdown(getData(data, false, false, '')));
  else {
    let msg = 'An error while loading user!';
    if (data.detail)
      msg = `${removeHTML(data.detail)}`;
    dispatch(slice.actions.getSuperAdminsForDropdown(getData(emptyListingObject, true, false, msg)));
  }
  NProgress.done();
};
export const getStoreManagersForDropdown = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { data, status } = await api('user', 'get', params);
  const getData = (data: any, error: boolean, success: boolean, message: string) => ({
    records: data, error, success, message, loading: false,
  });
  if (status === 200)
    dispatch(slice.actions.getStoreManagersForDropdown(getData(data, false, false, '')));
  else {
    let msg = 'An error while loading user!';
    if (data.detail)
      msg = `${removeHTML(data.detail)}`;
    dispatch(slice.actions.getStoreManagersForDropdown(getData(emptyListingObject, true, false, msg)));
  }
  NProgress.done();
};
export const getFloorManagersForDropdown = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { data, status } = await api('user', 'get', params);
  const getData = (data: any, error: boolean, success: boolean, message: string) => ({
    records: data, error, success, message, loading: false,
  });
  if (status === 200)
    dispatch(slice.actions.getFloorManagersForDropdown(getData(data, false, false, '')));
  else {
    let msg = 'An error while loading user!';
    if (data.detail)
      msg = `${removeHTML(data.detail)}`;
    dispatch(slice.actions.getFloorManagersForDropdown(getData(emptyListingObject, true, false, msg)));
  }
  NProgress.done();
};
export const getClerksForDropdown = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { data, status } = await api('user', 'get', params);
  const getData = (data: any, error: boolean, success: boolean, message: string) => ({
    records: data, error, success, message, loading: false,
  });
  if (status === 200)
    dispatch(slice.actions.getClerksForDropdown(getData(data, false, false, '')));
  else {
    let msg = 'An error while loading user!';
    if (data.detail)
      msg = `${removeHTML(data.detail)}`;
    dispatch(slice.actions.getClerksForDropdown(getData(emptyListingObject, true, false, msg)));
  }
  NProgress.done();
};

export default slice;