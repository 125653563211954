import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { pascalCase } from 'change-case';
import type { AppThunk } from 'src/store';
import { Product, ImportedFiles, Product as ProductInterface } from 'src/types/product';
import { api } from 'src/utils/api';
import { removeHTML } from 'src/utils/common';
import genericActions, { emptyListingObject } from './common';
import { getSelectedClientId } from 'src/utils/client';
import NProgress from 'nprogress';

export interface ImportFailure {
  productImportDto: Product;
  message: string;
  recordNumber: number;
  fileName: string;
}
export interface Import {
  imports: Product[];
  importCount: number;
  failures: ImportFailure[],
  productsAdded: number,
  productsUpdated: number,
  productsRejected: number;
  productsUnchanged: number;
  productsDeleted: number;
  fileId: string;
}
export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: ProductInterface[],
  pageSize: number,
  skip: number,
}

export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  selectedRecord?: ProductInterface;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
  import: Import;
  importing: boolean;
  importedFiles: ImportedFiles;
}

const emptyObject = {
  id: 0,
  reference: '',
  name: '',
  description: '',
  imageUrl: '',
  type: '',
  isActive: false,
};

const emptyImport = {
  imports: [],
  importCount: 0,
  failures: [],
  productsAdded: 0,
  productsUpdated: 0,
  productsRejected: 0,
  productsUnchanged: 0,
  productsDeleted: 0,
  fileId: '',
};

const emptyImportedFiles = {
  fileId: '',
  filename: ''

};

const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
  import: emptyImport,
  importing: false,
  importedFiles: emptyImportedFiles
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    setImporting(state: ReduxStoreObject, action: PayloadAction<{ importing: boolean; }>) {
      const { importing } = action.payload;
      state.importing = importing;
      state.actionType = 'importing';
    },
    resetSelected(state: ReduxStoreObject) {
      state.selectedRecord = emptyObject;
    },
    upload(state: ReduxStoreObject, action: PayloadAction<{ importedFiles: ImportedFiles; importedData: Import; error: boolean; success: boolean; message: string; importing: boolean; }>) {
      const { importedFiles, importedData, success, error, message, importing } = action.payload;
      state.importedFiles = importedFiles;
      state.import = importedData;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'import';
      if (importing !== null)
        state.importing = importing;
    },
    reset(state: ReduxStoreObject) {
      const { records, selectedRecord, success, error, loading, actionType, import: importData, importedFiles: importedFilesData, importing, message } = initialState;
      state.records = records;
      state.selectedRecord = selectedRecord;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = actionType;
      state.import = importData;
      state.importing = importing;
      state.loading = loading;
      state.importedFiles = importedFilesData;

    },
    resetImportedFiles(state: ReduxStoreObject) {
      state.importedFiles = emptyImportedFiles;
      state.import = emptyImport;
    },
  }
});

export const reducer = slice.reducer;

const resource = 'products';
export const { getAll, get, set, put, patch, remove, resetError, resetSuccess, reset } = genericActions(slice.actions, resource, emptyObject);
export const upload = (obj: any): AppThunk => async (dispatch) => {
  const getData = (importedFiles: ImportedFiles, importedData: Import, error: boolean, success: boolean, message: string) => ({
    importedFiles, importedData, error, success, message, importing: false,
  });
  try {
    NProgress.start();
    dispatch(slice.actions.setImporting({ importing: true }));
    const { status, data } = await api(`${resource}/ImportAsync?clientId=${getSelectedClientId()}`, 'post', obj,{
      'content-type': 'multipart/form-data',
    });

    if (status === 200) {
      dispatch(slice.actions.upload(getData(data, emptyImport, false, true, `${pascalCase(resource)} import will start in few seconds.`)));
    } else {
      let msg = `An error while uploading ${pascalCase(resource)}!`;
      if (data.detail)
        msg = `${removeHTML(data.detail)}`;
      dispatch(slice.actions.upload(getData(emptyImportedFiles, emptyImport, true, false, msg)));
    }
    NProgress.done();

  } catch (err) {
    dispatch(slice.actions.setImporting({ importing: true }));
    const msg = `An error while uploading ${pascalCase(resource)}!`;
    dispatch(slice.actions.upload(getData(emptyImportedFiles, emptyImport, true, false, msg)));
  }
};

export const uploadComplete = (obj: any): AppThunk => async (dispatch) => {
  const getData = (importedFiles: ImportedFiles, importedData: Import, error: boolean, success: boolean, message: string) => ({
    importedFiles, importedData, error, success, message, importing: false,
  });
  try {
    if (obj?.details[0].action === 'Failed') {
      const msg = obj?.details[0].message;
      dispatch(slice.actions.upload(getData(emptyImportedFiles, obj, true, false, msg)));

    } else {
      dispatch(slice.actions.upload(getData(emptyImportedFiles, obj, false, true, `${pascalCase(resource)} has been imported, please check the results.`)));
    }
  } catch (err) {
    const msg = `An error while uploading ${pascalCase(resource)}!`;
    dispatch(slice.actions.upload(getData(emptyImportedFiles, obj, true, false, msg)));
  }

};

export const resetImportedFiles = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.resetImportedFiles());
};

export default slice;
