import moment from 'moment';
export const useMomentLocale = (): void => {
    moment.locale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'seconds',
            m: 'minute',
            mm: '%d minutes',
            h: 'an hour',
            hh: '%d hours',
            d: 'day',
            dd: '%d days',
            M: 'a mth',
            MM: '%d mths',
            y: 'year',
            yy: '%d years'
        }
    });
};
