import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { restoreSettings, storeSettings } from 'src/contexts/SettingsContext';
import useAuth from './useAuth';
import { useDispatch } from 'src/store';
import { reset as productReset } from 'src/slices/product';
import { reset as userReset } from 'src/slices/user';
import { reset as stationReset } from 'src/slices/station';
import { reset as clientReset } from 'src/slices/client';
import { reset as stationGroupReset } from 'src/slices/stationGroup';
import { reset as globalProfilesReset } from 'src/slices/globalProfiles';
import { appInsights,severityLevel} from 'src/utils/appInsight';

export const useLogout = () => {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const doLogout = async (): Promise<void> => {
    try {
      await logout();
      const settings = restoreSettings();
      if (settings) {
        delete settings.client;
        delete settings.clientName;
        delete settings.tables;
        storeSettings(settings);
      }
      dispatch(productReset());
      dispatch(userReset());
      dispatch(stationReset());
      dispatch(clientReset());
      dispatch(stationGroupReset());
      dispatch(globalProfilesReset());
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
      appInsights.trackException({ exception: err, severityLevel: severityLevel.Error });
    }
  };
  return doLogout;
};