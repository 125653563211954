import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UnknownItems } from 'src/types/unknownItems';
import genericActions, { emptyListingObject } from './common';

export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: UnknownItems[],
  pageSize: number,
  skip: number,
}

export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = [];

const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'unknownItems',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;
      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
  }
});

export const reducer = slice.reducer;

const resource = 'Transactions/UnknownItems';
export const { getAll } = genericActions(slice.actions, resource, emptyObject);

export default slice;