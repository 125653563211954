export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  EXO_DARK: 'EXO_DARK',
  EXO_LIGHT: 'EXO_LIGHT',
  EXO_BLUE: 'EXO_BLUE',
  DARK_MODE: 'DARK_MODE',
};
