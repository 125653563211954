import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TunnelHistory, Box as BoxDataInterface } from 'src/types/tunnelHistory';
import genericActions, { emptyListingObject } from './common';
import NProgress from 'nprogress';
import { api } from 'src/utils/api';
import { AppThunk } from 'src/store';
import  { ActionTypes } from 'src/types/actionTypes';

export interface ReduxTunnelHistoryListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: TunnelHistory[],
  pageSize: number,
  skip: number,
}

export interface ReduxTunnelHistoryObject {
  records?: ReduxTunnelHistoryListObject;
  selectedRecord?: BoxDataInterface[];
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}
const emptyObject = [];

const initialState: ReduxTunnelHistoryObject = {
  records: emptyListingObject,
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'tunnelHistory',
  initialState,
  reducers: {
    getAll(state: ReduxTunnelHistoryObject, action: PayloadAction<ReduxTunnelHistoryObject>) {
      const { records, error, message, loading } = action.payload;
      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = ActionTypes.getAll;
    },
    get(state: ReduxTunnelHistoryObject, action: PayloadAction<ReduxTunnelHistoryObject>) {
      const { selectedRecord, error, message, loading } = action.payload;
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = ActionTypes.get;      
    },
    resetError(state: ReduxTunnelHistoryObject) {
      state.error = false;
      state.message = '';
      state.actionType = ActionTypes.resetError;
    },
    resetSuccess(state: ReduxTunnelHistoryObject) {
      state.success = false;
      state.message = '';
      state.actionType = ActionTypes.resetSuccess;
    },
    setLoading(state: ReduxTunnelHistoryObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = ActionTypes.loading;
    },
    resetSelected(state: ReduxTunnelHistoryObject) {
      state.selectedRecord = emptyObject;
    }
  }
});

export const get = (id: number): AppThunk => async (dispatch) => {
  try {
    NProgress.start();
    dispatch(slice.actions.setLoading({ loading: true }));
    const { status, data } = await api(`InventorySession/box/${id}`, 'get');

    const getData = (data, success, error, message): ReduxTunnelHistoryObject => ({
      selectedRecord: data,
      success,
      error,
      message,
      loading: false,
    });
    if (status === 200)
      dispatch(slice.actions.get(getData(data, false, '', true)));
    else
      dispatch(slice.actions.get(getData([], true, 'An error while loading box items!', false)));

    NProgress.done();
  } catch (err) {
    console.log(err);
  }
};

export const reducer = slice.reducer;

export const { getAll, getAllForDropdown, resetError, resetSuccess } = genericActions(slice.actions, 'InventorySession/boxes', emptyObject);

export default slice;