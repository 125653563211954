import MomentUtils from '@date-io/moment';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { useMomentLocale } from 'src/utils/moment';
import NotificationsHandler from './components/NotificationsHandler';
import { SocketProvider } from './contexts/SocketContext';
import UpdateHandler from './updateHandler';
import { appInsights } from 'src/utils/appInsight';
import AdminRoutes from 'src/routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

appInsights.trackPageView();

const App: FC = () => {
  const { settings } = useSettings();

  useMomentLocale();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <UpdateHandler>
              <BrowserRouter >
                <AuthProvider>
                  <SocketProvider>
                    <NotificationsHandler />
                    <GlobalStyles />
                    <ScrollReset />
                    <GoogleAnalytics />
                    <AdminRoutes />
                  </SocketProvider>
                </AuthProvider>
              </BrowserRouter>
            </UpdateHandler>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
