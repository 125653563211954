import type { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => {
  const accessToken: string = localStorage.getItem('accessToken');
  const isAuthenticated = accessToken ? true : false;
  const nProgressZIndex = isAuthenticated ? '0' : '1031';
  const { customs: { loader: { nProgress } } } = theme;
  return createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      '#nprogress .spinner-icon': {
        'border-top-color': nProgress,
        'border-left-color': nProgress,
      },
      '#nprogress .bar': {
        background: nProgress,
        'z-index': nProgressZIndex,
      },
      '#nprogress .peg': {
        'box-shadow': `0 0 10px ${nProgress}, 0 0 5px ${nProgress}`,
      },
      '#nprogress .spinner': {
        'z-index': '1300',
        top: '6px',
        right: '6px',
      },
      'a.active': {
        color: theme.palette.secondary.main,
        '& $title': {
          fontWeight: theme.typography.fontWeightMedium as 'bold'
        },
        '& $icon': {
          color: theme.palette.secondary.main
        }
      }
    }
  });
});

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
