import {
  Badge,
  Avatar,
  Box,
  LinearProgress,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText, makeStyles, Popover,
  SvgIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import type { FC } from 'react';
import React, {
  useEffect, useRef,
  useState
} from 'react';
import {
  Bell as BellIcon, MessageCircle as MessageIcon, Package as PackageIcon, Truck as TruckIcon
} from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'src/store';
import type { Theme } from 'src/theme';
import { getSelectedClientId } from 'src/utils/client';
import useSignalRProductImportStatusConnection from 'src/hooks/useSignalRProductImportStatusConnection';
import useSignalRProductImportStatusListener from 'src/hooks/useSignalRProductImportStatusListener';
import { ProductImportStatus } from 'src/types/alertNotification';
import { useSnackbar } from 'notistack';
import useSettings from 'src/hooks/useSettings';
import moment from 'moment';
import { api } from 'src/utils/api';
import { getProductImportStatusDescription } from 'src/utils/product';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: -3,
    marginRight: -2
  }, popover: {
    width: 320
  },
  formControl: {
    minWidth: 120,
  },
}));

const Notifications: FC = () => {
  const { settings, saveSettings } = useSettings();
  const { importProductsTriggerDisabledExpiry } = settings;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const notifications = [];
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isImportNotification, setImportNotification] = useState<boolean>(false);
  const [productImportNotificationText, setImportStatusDescription] = useState<string>(null);
  const { signalRPProductImportConnection } = useSignalRProductImportStatusConnection();

  const setProductImportInProgress = async () => {
    const clientId = getSelectedClientId();
    const { data, status } = await api(`ProductImports/clientId/${clientId}/inprogress`, 'get', {
      clientId: getSelectedClientId()
    });

    if (status === 200 && data == true && clientId) {
      setImportNotification(true);
      if(productImportNotificationText=='' || productImportNotificationText==null){
        setImportStatusDescription('Product import in progress');
      }

      if (importProductsTriggerDisabledExpiry != undefined) {
        delete importProductsTriggerDisabledExpiry[clientId];
        saveSettings({ ...settings, importProductsTriggerDisabledExpiry: { ...importProductsTriggerDisabledExpiry, [clientId]: moment().add(process.env.REACT_APP_TRIGGER_PRODUCT_IMPORT_TIMEOUT_SECONDS, 'seconds').unix().toString() } });
      }
      else {
        saveSettings({ ...settings, importProductsTriggerDisabledExpiry: { [clientId]: moment().add(process.env.REACT_APP_TRIGGER_PRODUCT_IMPORT_TIMEOUT_SECONDS, 'seconds').unix().toString() } });
      }
    }
    else{
      setImportNotification(false);
      if (importProductsTriggerDisabledExpiry != undefined) {
        delete importProductsTriggerDisabledExpiry[clientId];
      }
    }
  }

  const updateProductImportSettingDefault = (): void => {
    const clientId = getSelectedClientId();
    if (importProductsTriggerDisabledExpiry != undefined) {
      delete importProductsTriggerDisabledExpiry[clientId];
    }

    saveSettings({ ...settings, importProductsTriggerDisabledExpiry: { ...importProductsTriggerDisabledExpiry } });
  }

  const setProductImportNotification = (status: string): void => {

    const importStatus = getProductImportStatusDescription(status);
    setImportNotification(!importStatus.finished);
    setImportStatusDescription(importStatus.description);

    if (importStatus.finished) {
      setImportNotification(false);
      enqueueSnackbar(importStatus.description, {
        variant: importStatus.variant,
        autoHideDuration: 3000
      });

      updateProductImportSettingDefault();
    }
    else {
      setProductImportInProgress().then(() => {
        setImportStatusDescription(importStatus.description);
      });
      
    }
  }

  useSignalRProductImportStatusListener(signalRPProductImportConnection, getSelectedClientId(), setProductImportNotification, true, true);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    setProductImportInProgress();
  }, [dispatch]);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}>

          {isImportNotification ? (
            <Badge
              color="secondary"
              variant="dot"
              classes={{ badge: classes.badge }}>
              <SvgIcon>
                <BellIcon />
              </SvgIcon>
            </Badge>
          ) : (<SvgIcon> <BellIcon /> </SvgIcon>)}

        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notifications
          </Typography>
        </Box>
        {notifications?.length === 0 && isImportNotification == false ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>

              {notifications?.length > 0 && notifications.map((notification) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to="#"
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
            {notifications?.length ? <>
              <Box
                p={1}
                display="flex"
                justifyContent="center">
                <Button
                  component={RouterLink}
                  size="small"
                  to="#">
                  Mark all as read
                </Button>
              </Box></> : <></>}
          </>
        )}

        {isImportNotification ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              {productImportNotificationText}
              <LinearProgress color="secondary" />
            </Typography>
          </Box>
        ) : <></>}

      </Popover>
    </>
  );
};

export default Notifications;