import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { NotificationNew } from 'src/types/notification';
import genericActions, { emptyListingObject } from './common';
export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: NotificationNew[],
  pageSize: number,
  skip: number,
}

export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  selectedRecord?: NotificationNew[];
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = [];

const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxStoreObject) {
      state.selectedRecord = emptyObject;
    },
  }
});

export const reducer = slice.reducer;

const resource = 'Notifications/Notifications';
export const { getAll, get, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, resource, emptyObject);

export default slice;
