import {
  ProductImports
} from 'src/types/productImports';
import mock from 'src/utils/mock';

mock.onGet('/api/ProductImports').reply(() => {

  const data: ProductImports[] = [
    {
      id: 1,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV1',
      fileName: 'testcsv1',
      productsAdded: 264,
      productsUpdated: 0,
      failedProducts: 0,
    },
    {
      id: 2,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV2',
      fileName: 'testCsv2',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 3,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV3',
      fileName: 'testCsv3',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 4,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV4',
      fileName: 'testCsv4',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 5,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV5',
      fileName: 'testCsv5',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 6,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV6',
      fileName: 'testCsv6',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 7,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV7',
      fileName: 'testCsv7',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 8,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV8',
      fileName: 'testCsv8',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 9,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV9',
      fileName: 'testCsv9',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 10,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV10',
      fileName: 'testCsv10',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 11,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV11',
      fileName: 'testCsv11',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 12,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV12',
      fileName: 'testCsv12',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 13,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV13',
      fileName: 'testCsv13',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    }, {
      id: 14,
      startTime: '2021-12-23T13:55:00-05:00',
      endTime: '2021-12-23T14:55:00-05:00',
      source: 'CSV14',
      fileName: 'testCsv14',
      productsAdded: 364,
      productsUpdated: 0,
      failedProducts: 0,
    },
  ];

  return [200, {
    'page': 1,
    'totalPages': 2,
    'totalCount': 13,
    'rows': data,
    'pageSize': 10,
    'skip': 0,
  }
  ];
});
