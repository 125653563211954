/* eslint-disable no-unused-vars */
import { useSelector } from 'src/store';
import { Resources } from 'src/types/resources';
import { UserRoles } from 'src/types/user';

export enum Actions {
  delete = 'delete',
  update = 'update',
  write = 'write',
  read = 'read'
}
export interface AclProps {
  userRole?: UserRoles;
  resource: Resources;
  action: Actions;
}

const useAcl = ({ resource, action }: AclProps): boolean => {
  const { accessControl } = useSelector(state => state.accessControl);
  return accessControl[resource][action];
};

export default useAcl;