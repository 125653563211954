/* eslint-disable no-param-reassign */
import { colors } from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';
import 'moment-duration-format';
import { THEMES } from 'src/constants';
import { MessageLevel } from 'src/types/generic';
import { api } from './api';
import { restoreSettings } from 'src/contexts/SettingsContext';
import { RFIDReaderStatusToDisplay, StationRFIDReaderStatus, StationType, StatusDataType as StatusDataTypeInterface } from 'src/types/station';
import { Theme } from 'src/theme';

export const appType = 'Admin';

export enum LogLevel {
  Trace = 'Trace',
}

export type LogToCloudType = {
  message: string,
  logLevel?: LogLevel,
  Source?: string,
  data?: any;
};

export const arrayToJsonForOneProperty = (array, key, value, prefix = 'key') => {
  return array.reduce((accumulator, item) => {
    accumulator[prefix + item[key]] = item[value];
    return accumulator;
  }, {});
};

//remove html tags from a string, leaving only the inner text
export function removeHTML(str) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
}

export const truncate = (input, length = 25) => input?.length > length ? `${input.substring(0, length)}...` : input;

export const removeSpaces = (val: string) => val.replace(/\s+/g, '');

export const playSound = (url) => {
  const audio = new Audio(url);
  audio.play().then().catch((e) => { console.log('e', e); });
};

export const getTimeMinutes = (minutes: any) => {
  const sign = minutes < 0 ? '-' : '';
  const min = Math.floor(Math.abs(minutes));
  const sec = Math.floor((Math.abs(minutes) * 60) % 60);
  return `${sign + (min < 10 ? '0' : '') + min}:${sec < 10 ? '0' : ''}${sec}`;
};

export const getTimeDuration = (durationInSeconds: any) => {
  if (durationInSeconds) {
    return moment.duration(durationInSeconds, 'seconds').format('mm:ss', { trim: false });
  }
  return '00:00';
};

export const randomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
};

export const getDateRange = (time: string, timezone: string = null): { startDate?: string; endDate?: string; } => {
  const dateObject = {};
  switch (time) {
    case 'today':
      dateObject['startDate'] = moment.tz(timezone).format('YYYY-MM-DD 00:00:00');
      dateObject['endDate'] = moment.tz(timezone).format('YYYY-MM-DD 23:59:59');
      return dateObject;
    case 'last_hour':
      dateObject['endDate'] = moment.tz(timezone).format('YYYY-MM-DD hh:mm:ss');
      dateObject['startDate'] = moment.tz(timezone).subtract(1, 'hours').format('YYYY-MM-DD hh:mm:ss');
      return dateObject;
    case 'last_week':
      dateObject['endDate'] = moment.tz(timezone).format('YYYY-MM-DD 23:59:59');
      dateObject['startDate'] = moment.tz(timezone).subtract(7, 'd').format('YYYY-MM-DD 00:00:00');
      return dateObject;
    case 'last_30_days':
      dateObject['endDate'] = moment.tz(timezone).format('YYYY-MM-DD 23:59:59');
      dateObject['startDate'] = moment.tz(timezone).subtract(30, 'd').format('YYYY-MM-DD 00:00:00');
      return dateObject;
    case 'last_year':
      dateObject['endDate'] = moment.tz(timezone).format('YYYY-MM-DD 23:59:59');
      dateObject['startDate'] = moment.tz(timezone).subtract(365, 'd').format('YYYY-MM-DD 00:00:00');
      return dateObject;
    default:
      dateObject['startDate'] = moment.tz(timezone).format('YYYY-MM-DD 00:00:00');
      dateObject['endDate'] = moment.tz(timezone).format('YYYY-MM-DD 23:59:59');
      return dateObject;
  }

};

export const groupItems = (items) => {

  const getItems = items.reduce((result, item) => {
    if (item.name && item.sku && item.primaryIdentifier && item.icon && item.status) {
      result[item.status] = [...result[item.status] || [], item];
    } else {
      //Add a fake item for this unknown product
      let unknownItem = Object.assign({}, item);

      unknownItem = {
        id: item.id,
        name: item.name || 'Unknown',
        sku: item.sku || 'Unknown',
        status: item.status || 'Unknown',
        icon: item.icon || 'Unknown',
        primaryIdentifier: item.primaryIdentifier || 'Unknown'
      };
      result[unknownItem.status] = [...result[unknownItem.status] || [], unknownItem];

    }
    return result;
  }, {});

  const finalResult = [];

  Object.keys(getItems).forEach((item) => {
    if (getItems[item] && getItems[item][0]) {
      finalResult.push(
        ...getItems[item],
      );
    }
  });
  return finalResult;
};

export function formatSizeUnits(bytes) {
  let unit = 'Bytes';
  if ((bytes >> 30) & 0x3FF) {
    bytes = `${bytes >>> 30}.${bytes & (3 * 0x3FF)}`;
    unit = 'GB';
  } else if ((bytes >> 20) & 0x3FF) {
    bytes = `${bytes >>> 20}.${bytes & (2 * 0x3FF)}`;
    unit = 'MB';
  } else if ((bytes >> 10) & 0x3FF) {
    bytes = `${bytes >>> 10}.${bytes & (0x3FF)}`;
    unit = 'GB';
  } else if ((bytes >> 1) & 0x3FF) {
    bytes = (bytes >>> 1);
    unit = 'KB';
  } else {
    bytes = `${bytes}Byte`;
    unit = 'GB';
  }
  return { bytes, unit };
}

export function BytesToOtherUnit(bytes, unit) {
  switch (unit) {
    case 'GB':
      return `${bytes >>> 30}.${bytes & (3 * 0x3FF)}`;
    case 'MB':
      return `${bytes >>> 30}.${bytes & (3 * 0x3FF)}`;
    case 'KB':
      return `${bytes >>> 30}.${bytes & (3 * 0x3FF)}`;
    case 'Bytes':
      return `${bytes >>> 30}.${bytes & (3 * 0x3FF)}`;
    case 'Byte':
      return `${bytes >>> 30}.${bytes & (3 * 0x3FF)}`;
    default:
      return bytes;
  }
}

export const getColorByName = (name: string, theme: string) => {
  const character = name?.charAt(0)?.toUpperCase();
  const shade = theme === THEMES.DARK_MODE ? 200 : 800;
  switch (character) {
    case 'A':
      return colors.amber[shade];
    case 'B':
      return colors.blue[shade];
    case 'C':
      return colors.blueGrey[shade];
    case 'D':
      return colors.brown[shade];
    case 'E':
      return colors.cyan[shade];
    case 'F':
      return colors.deepOrange[shade];
    case 'G':
      return colors.deepPurple[shade];
    case 'H':
      return colors.green[shade];
    case 'I':
      return colors.grey[shade];
    case 'J':
      return colors.indigo[shade];
    case 'K':
      return colors.lightBlue[shade];
    case 'L':
      return colors.lightGreen[shade];
    case 'M':
      return colors.lime[shade];
    case 'N':
      return colors.orange[shade];
    case 'O':
      return colors.pink[shade];
    case 'P':
      return colors.purple[shade];
    case 'Q':
      return colors.red[shade];
    case 'R':
      return colors.teal[shade];
    case 'S':
      return colors.yellow[shade];
    case 'T':
      return colors.amber[shade];
    case 'U':
      return colors.blue[shade];
    case 'V':
      return colors.blueGrey[shade];
    case 'W':
      return colors.brown[shade];
    case 'X':
      return colors.cyan[shade];
    case 'Y':
      return colors.deepOrange[shade];
    case 'Z':
      return colors.deepPurple[shade];
    default:
      return colors.green[shade];
  }
};

export const ToastMessageVariant = (level: string) => {
  switch (level) {
    case MessageLevel.Info:
      return 'info';
    case MessageLevel.Error:
      return 'error';
    case MessageLevel.Warn:
      return 'warning';
    case MessageLevel.Success:
      return 'success';
    default:
      return 'info';
  }
};

export const logToCloud = async ({ message = '', data = {}, logLevel = LogLevel.Trace }: LogToCloudType): Promise<void> => {
  try {
    const requestPayload = {
      logLevel,
      message,
      source: appType,
      data
    };
    await api('logging', 'post', requestPayload);
  } catch (e) {
    console.error(e);
  }
};

export const getRfidReaderStatusToDisplay = (status: StationRFIDReaderStatus, theme: Theme): StatusDataTypeInterface => {
  const { customs: { station: { blue, green, purple, red, grey }, textOverDarkShades, textOverLightShades } } = theme;
  switch (status) {
    case StationRFIDReaderStatus.Listening:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Scanning,
        textColor: textOverDarkShades,
        color: blue
      };
    case StationRFIDReaderStatus.Paused:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Ready,
        textColor: textOverLightShades,
        color: green
      };
    case StationRFIDReaderStatus.Stopped:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Stopped,
        textColor: textOverLightShades,
        color: purple
      };
    case StationRFIDReaderStatus.Disconnected:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Disconnected,
        textColor: textOverDarkShades,
        color: red
      };
    case StationRFIDReaderStatus.Disabled:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Disabled,
        textColor: textOverLightShades,
        color: grey
      };
    case StationRFIDReaderStatus.UnknownReader:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.UnknownReader,
        textColor: textOverDarkShades,
        color: red
      };
    default:
      return {
        name: '',
        text: 'No Reader',
        textColor: 'none',
        color: 'none'
      };
  }
};

export const getSelectedClientId = () => {
  const settings: any = restoreSettings();
  return parseInt(settings?.client || 0);
};

export const getSelectedStoreId = () => {
  const settings: any = restoreSettings();
  return parseInt(settings?.store || 0);
};

export const resetStation = async (stationId: string | number, selectedRecord: any) => {
  try {
    const isValidator = selectedRecord?.stationType === StationType.Validator;
    const params = {
      'isValidatorApp': isValidator ? true : false,
      'voidedFrom': appType
    };
    return await api(`stations/permanentId/${stationId}/reset`, 'post', params);
  } catch (e) {
    console.error('error', e);
    return {};
  }
};

export const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;