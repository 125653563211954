import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Transaction, TransactionsRequestPayload } from 'src/types/transaction';
import { api } from 'src/utils/api';
import NProgress from 'nprogress';
import genericActions, { emptyListingObject } from './common';

export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: Transaction[],
  pageSize: number,
  skip: number,
}
export interface TransactionsState {
  recentTransactions: Transaction[];
  transactions: Transaction[];
  records?: ReduxStoreListObject;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  loadingMore: boolean;
  isMoreTrxAvailable: boolean;
  actionType?: string;
}

const initialState: TransactionsState = {
  recentTransactions: [],
  transactions: [],
  records: emptyListingObject,
  error: false,
  success: false,
  message: '',
  loading: true,
  loadingMore: false,
  isMoreTrxAvailable: true,
};

const emptyObject = [];
const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getRecentTransactions(state: TransactionsState, action: PayloadAction<TransactionsState>) {
      const { recentTransactions, loading, loadingMore } = action.payload;
      state.recentTransactions = [...recentTransactions];
      state.loading = loading;
      state.loadingMore = loadingMore;
    },
    getTransactions(state: TransactionsState, action: PayloadAction<TransactionsState>) {
      const { transactions, loading, loadingMore, isMoreTrxAvailable } = action.payload;

      state.transactions = [...transactions];
      state.loading = loading;
      state.loadingMore = loadingMore;
      state.isMoreTrxAvailable = isMoreTrxAvailable;
    },
    getMoreTransactions(state: TransactionsState, action: PayloadAction<TransactionsState>) {
      const { transactions, loading, loadingMore, isMoreTrxAvailable } = action.payload;
      state.transactions = [...state.transactions, ...transactions];
      state.loading = loading;
      state.loadingMore = loadingMore;
      state.isMoreTrxAvailable = isMoreTrxAvailable;
    },
    resetError(state: TransactionsState) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    setLoading(state: TransactionsState, action: PayloadAction<{ loading: boolean, loadingMore: boolean; }>) {
      const { loading, loadingMore } = action.payload;
      state.loading = loading;
      state.loadingMore = loadingMore;
      state.actionType = 'loading';
    },
    getAll(state: TransactionsState, action: PayloadAction<TransactionsState>) {
      const { records, error, message, loading } = action.payload;
      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    resetSuccess(state: TransactionsState) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    }
  }
});

export const getRecentTransactions = ({ stationId, lastTransactionId: transactionLastId = null, limit = 4, date = null }: TransactionsRequestPayload): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true, loadingMore: false }));
  const { status, data } = await api(`stations/${stationId}/transactions`, 'get', {
    id: transactionLastId,
    limit,
    date,
  });

  const getData = (data, success, error, message, isMoreTrxAvailable): TransactionsState => ({
    recentTransactions: data,
    transactions: [],
    error,
    success,
    message,
    loading: false,
    loadingMore: false,
    isMoreTrxAvailable
  });
  if (status === 200)
    dispatch(slice.actions.getRecentTransactions(getData(data, true, false, '', true)));
  else
    dispatch(slice.actions.getRecentTransactions(getData([], false, true, 'An error while loading orders history!', false)));

  NProgress.done();
};

export const getTransactions = ({ stationId, lastTransactionId = null, limit = 4, date = null }: TransactionsRequestPayload): AppThunk => async (dispatch) => {
  NProgress.start();
  if (lastTransactionId)
    dispatch(slice.actions.setLoading({ loading: false, loadingMore: true }));
  else
    dispatch(slice.actions.setLoading({ loading: true, loadingMore: false }));

  const { status, data } = await api(`stations/${stationId}/transactions`, 'get', {
    transactionId: lastTransactionId,
    limit,
    date,
  });

  const getData = (data, success, error, message, isMoreTrxAvailable): TransactionsState => ({
    recentTransactions: [],
    transactions: data,
    error,
    success,
    message,
    loading: false,
    loadingMore: false,
    isMoreTrxAvailable
  });
  if (status === 200)
    if (lastTransactionId) {
      const isMoreTrxAvailable = data.length ? true : false;
      dispatch(slice.actions.getMoreTransactions(getData(data, true, false, '', isMoreTrxAvailable)));
    } else {
      dispatch(slice.actions.getTransactions(getData(data, true, false, '', true)));
    }
  else
    dispatch(slice.actions.getTransactions(getData([], false, true, 'An error while loading orders history!', false)));

  NProgress.done();
};

const resource = 'Transactions';
export const reducer = slice.reducer;
export const { getAll, get, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, resource, emptyObject);
export default slice;