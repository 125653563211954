import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { DiscountInterface } from 'src/types/discount';
import genericActions, { emptyListingObject } from './common';

export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: DiscountInterface[],
  pageSize: number,
  skip: number,
}

export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  selectedRecord?: DiscountInterface;
  recordsForDropdown?: ReduxStoreListObject;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject: DiscountInterface = {
  id: 0,
  name: '',
  code: '',
  description: '',
  discountType: '',
  amount: 0,
  discountScope: '',
  isActive: false,
  activeFrom: null,
  activeTo: null,
  clientId: null
};

const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  selectedRecord: emptyObject,
  recordsForDropdown: emptyListingObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records: discounts, error, message, loading } = action.payload;
      state.records = discounts;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    getAllForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records: discounts, error, message, loading } = action.payload;
      state.recordsForDropdown = discounts;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAllForDropdown';
    },
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord: discountSelected, error, message, loading } = action.payload;
      if (discountSelected) {
        state.selectedRecord = discountSelected;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxStoreObject) {
      state.selectedRecord = emptyObject;
    },
    reset(state: ReduxStoreObject) {
      const { records, recordsForDropdown, selectedRecord, error, success, message, loading, actionType } = initialState;
      state.records = records;
      state.selectedRecord = selectedRecord;
      state.recordsForDropdown = recordsForDropdown;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = actionType;
    }
  }
});

export const reducer = slice.reducer;
export const { getAll, getAllForDropdown, get, set, put, patch, remove, resetError, resetSuccess, reset } = genericActions(slice.actions, 'discounts', emptyObject);
export default slice;
