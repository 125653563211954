import moment from 'moment';
import mock from 'src/utils/mock';
import {
    UserInterface,
} from 'src/types/user';

const users: UserInterface[] = [
    {
        id: 1,
        avatar: '',
        firstName: 'Andrew',
        lastName: 'Rutter',
        email: 'andrew@gmail.com',
        roleId: 1,
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 2,
        avatar: '',
        firstName: 'Freddie',
        lastName: 'Sutton',
        email: 'freddie@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 3,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 4,
        avatar: '',
        firstName: 'vcbcgv',
        lastName: 'dkjgjhfkghjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 5,
        avatar: '',
        firstName: 'dkjfdsfgdsgfhdsj',
        lastName: 'dkjfkfgfdlds',
        email: 'fdsgsd@gsdfgmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 6,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 7,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 8,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 9,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 10,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 11,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 12,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 13,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 14,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 15,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 16,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 17,
        avatar: '',
        firstName: 'dkjfhdsj',
        lastName: 'dkjfklds',
        email: 'fdsgsd@gmail.com',
        roleId: 2,
        status: 0,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
];

mock.onGet('/api/users').reply(() => {

    return [200, { users }];
});

mock.onGet('/api/users/1').reply((config) => {
    const user = users.filter(user => user.id === parseInt(config.params.id))[0];
    return [200, { user }];
});