/* eslint-disable no-unused-vars */
enum permissionType {
  Granted = 'granted',
  Denied = 'denied',
  Default = 'default',
}

const browserNotification = (notificationObj: Notification) => {
  const showNotification = () => {
    new Notification(notificationObj.title, {
      body: notificationObj.body,
      icon: `${window.location.origin}/static/icons/logo_x72.png`,
      tag: 'greeting-notify',
    });

    // notification.onclick = (e) => {
    // }
  };

  if (Notification.permission === permissionType.Granted) {
    showNotification();
  } else if (Notification.permission !== permissionType.Denied) {
    Notification.requestPermission().then(permission => {
      if (permission === permissionType.Granted) {
        showNotification();
      }
    });
  }
};

export default browserNotification;