import moment from 'moment';
import mock from 'src/utils/mock';
import {
    ClientInterface,
} from 'src/types/client';

const clients: ClientInterface[] = [
    {
        id: 1,
        name: 'Stark Center',
        contactPersonFirstName: 'Andrew',
        contactPersonLastName: 'Rutter',
        email: 'andrew@gmail.com',
        phoneNumber: 1234567890,
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
    {
        id: 2,
        name: 'Little Company',
        contactPersonFirstName: 'Charlie',
        contactPersonLastName: 'Little',
        email: 'charlie@gmail.com',
        phoneNumber: 1234567890,
        status: 1,
        createdAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate(),
        updatedAt: moment().subtract(1, 'days')
            .subtract(7, 'hours').toDate()
    },
];

mock.onGet('/api/clients').reply(() => {

    return [200, { clients }];
});

mock.onGet('/api/clients/1').reply((config) => {
    const client = clients.filter(client => client.id === parseInt(config.params.id))[0];
    return [200, { client }];
});