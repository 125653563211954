/* eslint-disable no-unused-vars */
import { Store } from './store';

export type InventoryType =
  | 'in_stock'
  | 'limited'
  | 'out_of_stock';

export enum ProductType {
  type1 = 'type1',
  type2 = 'type2',
  type3 = 'type3',
  type4 = 'type4',
}
export interface ProductOld {
  id: string;
  attributes: string[];
  category: string;
  createdAt: number;
  currency: string;
  image: string | null;
  inventoryType: InventoryType;
  isAvailable: boolean;
  isShippable: boolean;
  name: string;
  price: number;
  quantity: number;
  updatedAt: number;
  variants: number;
}
export interface UPC {
  upc: string;
  productId: string;
  id: number;
  modifiedOn: string;
  modifiedBy: string;
}
export interface ProductDepreciated {
  id: string;
  name: string;
  description: string;
  sku: string;
  epc?: string;
  upc: string;
  epcPrefix: string;
  iconUrl: string;
  imageUrl: string;
  manufacturer: string;
  type: ProductType;
  updatedAt: number;
}
export interface Product {
  id: number;
  reference?: string;
  name: string;
  description: string;
  sku?: string;
  upc?: string;
  externalId?: string;
  productUpcs?: UPC[]; // productUpcs for add/Edit multiple upcs
  upcs?: string; //upcs for get comma separated string for listing
  epcPrefix?: string;
  icon?: string;
  imageUrl: string;
  manufacturer?: string;
  price?: string;
  type: string;
  createdOn?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  isActive: boolean;
  notes?: string;
  store?: Store;
  storeId?: number;
  storeName?: string;
  color?: string;
  size?: string;
}
export interface ProductsList {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: Product[],
  pageSize: number,
  skip: number,
}
export interface ImportedFiles {
  fileId: string;
  filename: string;
}
export interface ImportedFilesStatus {
  fileId: string;
  percentComplete: number;
}

export enum ItemStatus {
  New       = 'New',
  Voided    = 'Voided',
  Returned  = 'Returned',
  Purchased = 'Purchased',
}