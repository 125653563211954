import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Location } from 'src/types/locations';
import genericActions, { emptyListingObject } from './common';

export interface ReduxLocationListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: Location[],
  pageSize: number,
  skip: number,
}
export interface ReduxLocationObject {
  records?: ReduxLocationListObject;
  recordsForDropdown?: ReduxLocationListObject;
  selectedRecord?: Location;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = {
  id: 0,
  name:'',
  description:'',
  integrationAPIKey:'',
  timezone:'',
  notes:'',
  clientId:0,
  entryPoint: '',
  permanentId: '',
  isActive: true,
  isDeleted: true,
  createdOn: '',
  modifiedOn: '',
  createdBy: '',
  modifiedBy: '',
  version: 0,
};

const initialState: ReduxLocationObject = {
  records: emptyListingObject,
  recordsForDropdown: emptyListingObject,
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    getAll(state: ReduxLocationObject, action: PayloadAction<ReduxLocationObject>) {
      const { records, error, message, loading } = action.payload;
      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    getAllForDropdown(state: ReduxLocationObject, action: PayloadAction<ReduxLocationObject>) {
      const { records: clients, error, message, loading } = action.payload;
      state.recordsForDropdown = clients;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAllForDropdown';
    },
    get(state: ReduxLocationObject, action: PayloadAction<ReduxLocationObject>) {
      const { selectedRecord, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxLocationObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxLocationObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxLocationObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxLocationObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxLocationObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxLocationObject) {
      state.selectedRecord = emptyObject;
    }
  }
});

export const reducer = slice.reducer;

export const { getAll, getAllForDropdown, get, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, 'location', emptyObject);

export default slice;