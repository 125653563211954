import mock from 'src/utils/mock';
import type {
  Store,
} from 'src/types/store';

mock.onGet('/api/stores').reply(() => {
  const stores: Store[] = [
    {
      id: 1,
      name: 'Store 1',
      contactName: 'Freddie Sutton',
      phone: '1234567890',
      email: 'fs@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'abc',
        address2: 'xyz',
        city: 'New York',
        state: 'New York',
        zip: '123456'
      }
    },
    {
      id: 2,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 3,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 4,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 5,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 6,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 7,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 8,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 9,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 10,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 11,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 12,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 13,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 14,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 15,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 16,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 17,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
    {
      id: 18,
      name: 'Store 2',
      contactName: 'Andrew Store',
      phone: '1234567890',
      email: 'andrew@stark.com',
      notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      address: {
        address1: 'pqr',
        address2: 'efg',
        city: 'New York',
        state: 'New York',
        zip: '678901'
      }
    },
  ];

  return [200, { stores }];
});

mock.onGet('/api/stores/1').reply(() => {
  const store: Store = {
    id: 1,
    name: 'Store 1',
    contactName: 'Freddie Sutton',
    phone: '1234567890',
    email: 'fs@stark.com',
    notes: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    address: {
      address1: 'abc',
      address2: 'xyz',
      city: 'New York',
      state: 'New York',
      zip: '123456'
    }
  };
  return [200, { store }];
});