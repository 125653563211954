import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import NProgress from 'nprogress';
import type { AppThunk } from 'src/store';
import { LatestStationHealth, Station as StationInterface, StationActivities, StationReads, TransactionSummary } from 'src/types/station';
import { api } from 'src/utils/api';
import genericActions, { emptyListingObject } from './common';
export interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: StationInterface[],
  pageSize: number,
  skip: number,
}
export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  recordsForDropdown?: ReduxStoreListObject;
  selectedRecord?: StationInterface;
  activities?: StationActivities[],
  reads?: StationReads,
  summary?: TransactionSummary,
  latestStationHealth?: LatestStationHealth,
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = {
  id: 0,
  name: '',
  stationGroup: {
    id: 0,
    name: '',
  },
};
const emptyActivities = [];
const emptyReads = { data: [], labels: [] };

const emptySummary = {
  completedTransactionCount: 0,
  averagePurchasedItems: 0,
  averageScanDuration: 0,
  averagePaymentDuration: 0,
  averageTransactionDuration: 0,
  averageItems: 0,
};

const emptyLatestStationHealth = {
  id: 0,
  cpu: 0,
  memory: 0,
  availableMemory: 0,
  threadCount: 0,
  readerStatus: []
};
const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  recordsForDropdown: emptyListingObject,
  selectedRecord: emptyObject,
  activities: emptyActivities,
  reads: emptyReads,
  summary: emptySummary,
  latestStationHealth: emptyLatestStationHealth,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    getAllForDropdown(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records: clients, error, message, loading } = action.payload;

      state.recordsForDropdown = clients;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAllForDropdown';
    },
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, error, message, loading } = action.payload;
      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxStoreObject) {
      state.selectedRecord = emptyObject;
    },
    getActivities(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { activities, error, message, loading } = action.payload;
      state.activities = activities;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getActivities';
    },
    getReads(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { reads, error, message, loading } = action.payload;
      state.reads = reads;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getReads';
    },
    getSummary(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { summary, error, message, loading } = action.payload;
      state.summary = summary;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getSummary';
    },
    getLatestStationHealth(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { latestStationHealth, error, message, loading } = action.payload;
      state.latestStationHealth = latestStationHealth;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getLatestStationHealth';
    },
    reset(state: ReduxStoreObject) {
      const { records, recordsForDropdown, selectedRecord, activities, reads, summary, error, success, message, loading, actionType } = initialState;
      state.records = records;
      state.recordsForDropdown = recordsForDropdown;
      state.selectedRecord = selectedRecord;
      state.activities = activities;
      state.reads = reads;
      state.summary = summary;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = actionType;
    }
  }
});

export const reducer = slice.reducer;
const resource = 'Stations';
export const { getAll, getAllForDropdown, get, set, put, patch, remove, resetError, resetSuccess, reset } = genericActions(slice.actions, 'stations', emptyObject);
export const getActivities = (params = {}): AppThunk => async (dispatch) => {
  NProgress.start();
  dispatch(slice.actions.setLoading({ loading: true }));
  const { status, data } = await api('Stations/activities', 'get', params);

  const getData = (data, success, error, message): ReduxStoreObject => ({
    activities: data,
    success,
    error,
    message,
    loading: false,
  });
  if (status === 200)
    dispatch(slice.actions.getActivities(getData(data, false, '', true)));
  else
    dispatch(slice.actions.getActivities(getData([], true, 'An error while loading station activities!', false)));

  NProgress.done();
};

export const getReads = (params = {}): AppThunk => async (dispatch) => {
  try {
    NProgress.start();
    dispatch(slice.actions.setLoading({ loading: true }));
    const { status, data } = await api('Stations/reads', 'get', params);

    const getData = (data, success, error, message): ReduxStoreObject => ({
      reads: data,
      success,
      error,
      message,
      loading: false,
    });
    if (status === 200)
      dispatch(slice.actions.getReads(getData(data, false, '', true)));
    else
      dispatch(slice.actions.getReads(getData([], true, 'An error while loading station reads!', false)));

    NProgress.done();
  } catch (err) {
    console.log(err);
  }
};

export const getSummary = (params = {}): AppThunk => async (dispatch) => {
  try {
    NProgress.start();
    dispatch(slice.actions.setLoading({ loading: true }));
    const { status, data } = await api('Transactions/summary', 'get', params);

    const getData = (data, success, error, message): ReduxStoreObject => ({
      summary: data,
      success,
      error,
      message,
      loading: false,
    });
    if (status === 200)
      dispatch(slice.actions.getSummary(getData(data, false, '', true)));
    else
      dispatch(slice.actions.getSummary(getData([], true, 'An error while loading station summary!', false)));

    NProgress.done();
  } catch (err) {
    console.log(err);
  }
};

export const getLatestStationHealth = (id): AppThunk => async (dispatch) => {
  try {
    NProgress.start();
    dispatch(slice.actions.setLoading({ loading: true }));
    const { status, data } = await api(`${resource}/${id}/LatestStationHealth`, 'get');

    const getData = (data, success, error, message): ReduxStoreObject => ({
      latestStationHealth: data,
      success,
      error,
      message,
      loading: false,
    });
    if (status >= 200 && status <= 299)
      dispatch(slice.actions.getLatestStationHealth(getData(data, false, '', true)));
    else
      dispatch(slice.actions.getLatestStationHealth(getData([], true, 'An error while loading station health!', false)));

    NProgress.done();
  } catch (err) {
    console.log(err);
  }
};
export default slice;