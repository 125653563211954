/* eslint-disable no-unused-vars */
import { Store } from './store';

export interface StationGroup {
  id: number;
  name: string,
  locationDescription?: string,
  layout?: layout,
  updatedAt?: number;
  storeId?: number;
  store?: Store;
  isActive?: boolean;
  notes?: string;
}

export enum layout {
  square = 'SQUARE',
  horizontal = 'HORIZONTAL',
  vertical = 'VERTICAL',
  irregular = 'IRREGULAR',
}