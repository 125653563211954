export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const uiConfig = {
  listSize: process.env.REACT_APP_LIST_SIZE,
};

//That config will work for both cypress tesing and NodeJs Process
//When we run cypress that time process varaible will not available
export const processConfig = {
  REACT_APP_APPLICATION_INSIGHT_INSTRUMENTATION_KEY:
    'Cypress' in window
      ? '86725e47-a652-413d-b267-8f905ea5a8ea'
      : process.env.REACT_APP_APPLICATION_INSIGHT_INSTRUMENTATION_KEY
};