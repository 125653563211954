import { Avatar, Box, ButtonBase, Hidden, makeStyles, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useLogout } from 'src/hooks/useLogout';
import type { Theme } from 'src/theme';
import getInitials from 'src/utils/getInitials';
import { getColorByName } from '../../../utils/common';
const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { user } = useAuth();
  const [isOpen, setOpen] = useState<boolean>(false);
  const theme: Theme = useTheme();
  const doLogout = useLogout();
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleLogout = async (): Promise<void> => {
    handleClose();
    doLogout();
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar
          alt="User"
          style={{
            backgroundColor: getColorByName(user?.firstName, theme.name)
          }}
          className={classNames(classes.avatar)
          }
          src={user.avatar}
        >
          {getInitials(user?.firstName)}
          {getInitials(user?.lastName)}
        </Avatar>
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {user?.firstName} {user?.lastName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/account"
        >
          Account
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
