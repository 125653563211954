import { useSnackbar } from 'notistack';
import React, { FC, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import useSignalRNotificationsConnection from 'src/hooks/useSignalRNotificationsConnection';
import useSignalRNotificationsListener from 'src/hooks/useSignalRNotificationsListener';
import { StationMessage } from 'src/types/station';
import { ToastMessageVariant } from 'src/utils/common';

const NotificationsHandler: FC = () => {
  const { signalRConnection } = useSignalRNotificationsConnection();
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [stationToastMessage, setStationToastMessage] = useState<StationMessage>(null);
  useSignalRNotificationsListener(signalRConnection, true, isAuthenticated, setStationToastMessage);

  const message = stationToastMessage?.message;
  const level = stationToastMessage?.level;
  const variant = ToastMessageVariant(level);

  useEffect(() => {
    if (message) {
      enqueueSnackbar(`${message}`, {
        variant: variant,
        autoHideDuration: 3000
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (<></>);
};
export default NotificationsHandler;
