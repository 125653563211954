import mock from 'src/utils/mock';
import type { AlertAndNotification } from 'src/types/alertAndNotification';
import { AlertAndNotification as AlertAndNotificationInterface, } from 'src/types/alertAndNotification';

const alertsAndNotifications: AlertAndNotificationInterface[] = [
    {
        id: 1,
        event: 'Configuration Applied',
        alertType: 'Push Notification (Management Console),Push Notification (Clerk Console),SMS,Email',
        receivers: 'Super Admin,Client Admin,Store Manager,Floor Manager,Clerk',
        enable: true
    },
    {
        id: 2,
        event: 'Bad TOF Sensor',
        alertType: 'Push Notification (Management Console),Push Notification (Clerk Console),SMS,Email',
        receivers: 'Super Admin,Client Admin,Store Manager,Floor Manager,Clerk',
        enable: true
    },
];

mock.onGet('/api/alerts-and-notifications').reply(200, alertsAndNotifications);
mock.onGet('/api/alertsAndNotifications/1').reply(() => {
    const data: AlertAndNotification = {
        id: 1,
        event: 'Configuration Applied',
        alertType: 'Push Notification (Management Console),Push Notification (Clerk Console),SMS,Email',
        receivers: 'Super Admin,Client Admin,Store Manager,Floor Manager,Clerk',
        enable: true
    };
    return [200, data];
});